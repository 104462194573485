import React from 'react';
import {StyledWalletList} from "./StyledWalletList";
import {NavLink} from "react-router-dom";

const WalletList = ({open,setIsOpen, disconnect, copy}) => {

    const withClose = (callBack) => {
        callBack();
        setIsOpen(false);
    }

    return (
        <StyledWalletList className={"wallet-list "} open={open}>
            <p onClick={()=> copy()}>copy address</p>
            <NavLink to="/" onClick={()=> withClose(() => {})}>wallet</NavLink>
            <p onClick={()=> withClose(() => disconnect())}>disconnect</p>
        </StyledWalletList>
    );
};

export default React.memo(WalletList);
