import styled from "styled-components";
import {baseTheme} from "../../../../styles/baseTheme";
import Button from "@mui/material/Button";

import ArrDwn from "../../../../assets/img/icons/WhiteArrow.svg";

export const StyledButton = styled(Button)`
  && {
    padding: 4px 12px;
    //width: 109px;
    height: 24px;
    background-color: ${baseTheme.colors.secondary};
    border-radius: 12px 0;
    color: #fff;
    font-family: ${baseTheme.fonts.secondary};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    border: none;
    will-change: filter;
    transition: .3s;
    animation: fadeIn 0.5s forwards ease-in-out;
    text-transform: capitalize;

    &.spectate {
      background-color: #fff;
      color: #000;
      font-weight: 500;
      text-transform: capitalize;
    }

    &.confirm {
      font-weight: 500;
      text-transform: capitalize;
    }

    &.connect {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-flex;
      align-items: center;
      transition: .4s;
      padding: 18px 0;
      border-radius: 20px 0;
      width: 161px;
      height: initial;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;

      ${({open}) => open ? "" : ""}
      &::after {
        content: ${({open}) => open ? "''" : ""};
        background-image: ${(open) => open ? `url(${ArrDwn})` : ""};
        margin-left: 6px;
        width: 15px;
        height: 15px;
        background-size: contain;
        background-repeat: no-repeat;
      }

      @media ${baseTheme.media.mobile} {  
        width: 130px;
        font-size: 14px;
        padding: 10px 0;
        border-radius: 15px 0;

          &::after {
            width: 13px;
            height: 14px;
          }
      }    
    }

    &.form__button {
      font-family: ${baseTheme.fonts.primary};
      background-color: ${props => props.open ? baseTheme.colors.secondary : "#1A1A30"};
      padding: 4px 16px;
      font-size: 12px;
      height: 25px;
    }

    &.mobile__filters {
      height: 38px;
      box-sizing: border-box;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #000;
      background-color: #fff;
      text-transform: capitalize;
      font-family: ${baseTheme.fonts.secondary};
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      padding-left: 20px;
      padding-right: 20px;

      svg {
        top: 1px;
        width: 16px;
        height: 16px;
      }

      &&:hover {
        background-color: ${baseTheme.colors.hoverSecondary};
        color: #fff;

        svg path {
          fill: #fff;
        }
      }
    }

    &.filter-button, &.back {
      height: 45px;
      color: #fff;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 29px;
      padding-left: 20px;
      padding-right: 20px;
      background-color: ${baseTheme.colors.headers};
      font-family: ${baseTheme.fonts.primary};

      &-white {
        background-color: #fff;
        height: 45px;
        color: #000;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 29px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: ${baseTheme.fonts.primary};
      }
    }

    &.reset, &.reset:hover {
      background-color: ${baseTheme.colors.bg};
      border: 1px solid ${baseTheme.colors.alert};
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: ${baseTheme.colors.alert};
      height: 38px;
      padding-left: 20px;
      padding-right: 20px;
      text-transform: capitalize;

      &:active {
        background-color: ${baseTheme.colors.alert};
        color: #fff;
        box-shadow: inset 0 0 4px 3px rgba(0, 0, 0, 0.5);
      }
    }

    &.back, &.apply {
      height: 38px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #EEEEEE;
      font-family: ${baseTheme.fonts.secondary};
    }

    &.active-filter {
      height: 28px;
      color: #fff;
      background: #2825BF;
      border-radius: 12px 0;
      text-align: center;
      padding: 6px 16px;
      border: none;
      box-sizing: border-box;
      font-family: 'Teko', sans-serif;
      cursor: pointer;
      pointer-events: painted;
      letter-spacing: 0.05em;
      margin-bottom: 21px;
      margin-right: 10px;
      font-size: 14px;

      svg {
        width: 7.44px;
        position: relative;
        top: 1px;
        left: 5px;
      }

      &&:hover {
        background-color: #fff;
        color: #2825BF;

        svg path {
          fill: #2825BF;
        }
      }
    }

    &.filter-item {
      border-radius: 12px 0;
      text-align: center;
      padding: 4px 16px;
      border: none;
      background-color: ${baseTheme.colors.headers};
      color: #fff;
      margin-left: 4px;
      height: 25px;
      box-sizing: border-box;
      font-family: ${baseTheme.fonts.primary};
      transition: ${baseTheme.durations.ms300};
      cursor: pointer;
      pointer-events: painted;

      &&:hover, &&.active {
        background-color: ${baseTheme.colors.hoverItem};
      }

      &.all {
        background-color: #fff;
        color: #000;
        margin-right: 24px;
        position: relative;
        margin-left: 0;
        min-width: 46px;

        &&.active {
          background-color: #fff;
        }

        &&:hover {
          background-color: ${baseTheme.colors.hoverItem};
          color: #fff;
        }

        &::after {
          content: "";
          width: 1px;
          height: 14px;
          background-color: #fff;
          right: -15px;
          position: absolute;
        }
      }
    }

    &.close {
      background-color: #494960;

      &:hover {
        background-color: #5b5b77;
      }
    }
  }


  &&:hover {
    background-color: ${baseTheme.colors.hoverSecondary};
    color: #fff;
  }

  &&:disabled {
    color: #070713;
    background-color: #fff;
  }

  @media ${baseTheme.media.mobile} {
    padding: 4px 12px;
    //width: 109px;
    height: 24px;
    background-color: ${baseTheme.colors.secondary};
    border-radius: 12px 0;
    color: #fff;
    font-family: ${baseTheme.fonts.secondary};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    border: none;
    will-change: filter;
    transition: .3s;
    animation: fadeIn 0.5s forwards ease-in-out;

    &&.active-filter {
      white-space: nowrap;
      margin-bottom: 0;
      height: 45px;
      padding: 8px 25px 8px 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 29px;

      svg {
        width: 8.5px;
        height: 8.5px;
        top: -1px;
      }

      &:hover {
        color: #fff;
        background: #2825BF;

        svg path {
          fill: #fff;
        }
      }
    }

    &.spectate {
      background-color: #fff;
      color: #000;
      font-weight: 500;
      font-size: 12px;
      padding: 2px 18px;
      box-sizing: content-box;
    }

    &.confirm {
      font-weight: 500;
      font-size: 16px;
      padding: 7px 12px;
      box-sizing: content-box;
      font-weight: 500;
    }

    &.mobile__filters {
      height: 38px;
      box-sizing: border-box;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #000;
      background-color: #fff;
      text-transform: capitalize;
      font-family: ${baseTheme.fonts.secondary};
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      padding-left: 20px;
      padding-right: 20px;

      svg {
        top: 1px;
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }

      &:hover {
        background-color: ${baseTheme.colors.hoverSecondary};
        color: #fff;

        svg path {
          fill: #fff;
        }
      }
    }

    &.filter-button {
      height: 45px;
      color: #fff;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 29px;
      padding-left: 20px;
      padding-right: 20px;
      background-color: ${baseTheme.colors.headers};
      font-family: ${baseTheme.fonts.primary};
      text-transform: capitalize;

      svg {
        margin-left: 5px;
        width: 21px;
        height: 24px;
        margin-bottom: 4px;

        path {
          fill: #fff;
        }
      }

      &-white {
        background-color: #fff;
        height: 45px;
        color: #000;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 29px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: ${baseTheme.fonts.primary};
      }
    }

    &.reset, &.reset:hover {
      background-color: ${baseTheme.colors.bg};
      border: 1px solid ${baseTheme.colors.alert};
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: ${baseTheme.colors.alert};
      height: 38px;
      padding-left: 20px;
      padding-right: 20px;

      &:active {
        background-color: ${baseTheme.colors.alert};
        color: #fff;

      }
    }

    &:hover {
      background-color: ${baseTheme.colors.hoverSecondary};
      color: #fff;
    }

    &:disabled {
      color: #070713;
      background-color: #fff;
    }
  }

  @media ${baseTheme.media.mobile} {

  }

`;
