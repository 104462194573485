import React from 'react';
import {StyledCounter} from "./StyledCounter";
import Button from "@mui/material/Button";

const Counter = ({counter, setCounter, maxCounter}) => {

    const decrease = () => counter > 1 ?  setCounter(--counter) : null;

    const increase = () => counter < (maxCounter ? maxCounter : 10 ) ?  setCounter(++counter) : null;

    return (
        <StyledCounter className="counter">
            <Button className="counter__button" onClick={()=>decrease()}>-</Button>
            {counter}
            <Button className="counter__button" onClick={()=>increase()}>+</Button>
        </StyledCounter>
    );
};

export default React.memo(Counter);