import styled from "styled-components";
import {baseTheme} from "../../../styles/baseTheme";

export const StyledError = styled.div`
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  .error {
    position: relative;
    margin: auto;
    width: 322px;
    height: 246px;
    background-color: #090922;
    border: 1px solid #2825BF;
    box-sizing: border-box;
    border-radius: 22px 0;
    z-index: 10;
    padding: 0 58px 24px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    text-align: center;

    &.solana-error {
      h2 {margin: 0;}
    }
    
    .error-exit {
      width:17.01px;
      height: 17.01px;
      position: absolute;
      right: 11.5px;
      top: 11.5px;
      cursor: pointer;
    }
    .error-title {
      font-family: ${baseTheme.fonts.primary};
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      text-align: center;
      text-transform: uppercase;
      margin-top: 82px;
      margin-bottom: 32px;
    }
    button {
      margin: 0 6px;
    }
    
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.25);
  }
`
