import axios from "axios";
import config from "../config";

export const getDogById = async (dogId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.SERVER_ADDRESS}/api/metadata/${dogId}`)
            .then((data) => resolve(data.data.body))
            .catch((err) => reject(err));
    })
}

export const createLobby = async (lobbyData) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.SERVER_ADDRESS}/api/lobby/`, lobbyData)
            .then((data) => resolve(data.data))
            .catch((err) => reject(err));
    })
}

export const returnLobby = async (lobbyId) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.SERVER_ADDRESS}/api/lobby/trackout/` + lobbyId)
            .then((data) => resolve(data.data))
            .catch((err) => reject(err));
    })
}
