import React from 'react';
import {StyledBarItem, StyledBar, StyledStatButton} from "./StyledBar";
import {StyledStatsProgress} from "../../../../Sections/DogeAnalyzer/DogAnalyzerEquipStats/StyledDAEquipStats";
import {StyledChar} from "../../../../Sections/DogeTrain/Training/Improvements/ImprovementsItem/StyledImprovementsItem";
import {ReactComponent as ArrUp} from "../../../../assets/img/icons/arrUp.svg";

const Bar = ({name, feature, newFeature, handleChange, noTrain}) => {

    const progressBars = Array.from(Array(5))

    const calcBarWidth = (index, char) => {
        index++;
        return  index * 20 <= char ? `100%` : (index-1) * 20 < char ? `${char % 20 * 5}%` : `0%`
    }

    const isUpgrade = newFeature > feature;

    return (
        <StyledBar noTrain>

            <div className="bars__container">

                <div className="stats">

                    {name}

                    <div className="stat-value">
                        {/*{newFeature} <span className="gray">/ 100</span>*/}
                        <StyledChar isUpgrade={isUpgrade}>
                            {isUpgrade ?
                                <>
                                    <ArrUp/>
                                    {newFeature}
                                    (+{newFeature - feature})
                                </>
                                : feature
                            }
                        </StyledChar>
                    </div>

                </div>

                {progressBars.map((e, index) => (
                    <StyledBarItem featureW={calcBarWidth(index, feature)} newFeatureW={calcBarWidth(index, newFeature)} key={index}/>
                ))}

            </div>

            {noTrain ? "" :
                <div className="stat-buttons">

                    <StyledStatButton id={name} disabled={newFeature <= feature}
                                      onClick={(e) => handleChange(e, "dec")}>-</StyledStatButton>

                    <StyledStatButton id={name} disabled={newFeature >= 100}
                                      onClick={(e) => handleChange(e, "inc")}>+</StyledStatButton>

                </div>
            }

        </StyledBar>
    );
};

export default React.memo(Bar);
