import styled from "styled-components";
import {baseTheme} from "../../styles/baseTheme";

export const StyledDACard = styled.div`
  font-family: ${baseTheme.fonts.secondary};
  display: flex;
  width: 100%;
  max-width: 540px;
  min-width: 215px;
  border: 1px solid ${baseTheme.colors.borders};
  background: #161629;
  
  &, * {
    box-sizing: border-box;
  }
  
  @media (max-width: 600px) {
    max-width: 414px;
  }
`

export const StyledNotFound = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-family: ${baseTheme.fonts.secondary};
  font-size: 11px;
  
  &.dog-analyzer {
    margin-top: 100px;
  }
  
  img {
    display: block;
    margin: 0 auto 25px auto;
  }
`
