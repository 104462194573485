import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {baseTheme} from "../../../../styles/baseTheme";

export const StyledMenuItem = styled(NavLink)`
  && {
    transition-delay: ${(props) => props.i * 0.05 + "s"};
  }
  font-style: normal;
  margin-left: 100%;
  line-height: 32px;
  font-weight: 500;
  font-family: ${baseTheme.fonts.primary};
  font-size: 15px;

  &&.filter-item {
    margin: 4px 0;
  }
  &&.sorting {
    display: flex;
    justify-content: space-between;
    &::after {
      content: none;
    }
  }
  &&.slider {
    padding: 0 27px;
  }
  &:hover, &.active {
    text-shadow: 0 0 3px rgb(255 255 255 / 68%);
  }
`