import styled from "styled-components";
import {baseTheme} from "../../../styles/baseTheme";

import ArrRight from "../../../assets/img/icons/WhiteArrow.svg"

export const StyledMobileMenu = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  font-family: ${baseTheme.fonts.primary};
  background-color: ${baseTheme.colors.bg};
  opacity: 0;
  pointer-events: none;
  &.wasOpened { 
    ${baseTheme.keyframes.fadeIn()};
    pointer-events: initial;
    ${(props) => !props.open ?
            baseTheme.keyframes.fadeOut()
            : ""}
    ${(props) => !props.open ?
            "pointer-events:none;"
            : ""}
    };
  }
  
  .menu__header {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 60px;
    border-bottom: 1px solid #494960;
    text-transform: uppercase;
    padding: 13.5px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    a {
      display: flex;
      align-items: center;
      color: #fff;
      padding: 0;
      svg {
        margin-right: 15.5px;
        transform: rotate(90deg);
        height: 15px;
        width: 15px;
        padding-right: 4px;
        
        path {
          fill: #fff;
        }
      }
    }
  }
  
  .menu__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > a, & >  button {
      width: 100%;
      margin: 12px 0 ;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 34px;
      text-transform: capitalize;
      position: relative;
      display: block;
      transition: none;
      &::after {
        content: ${props => props.showFilters ? "none" : `url(${ArrRight})` };
        position: absolute;
        right: 12px;
        top: -2px;
        transform: rotate(270deg);
        opacity: 0.7;
      }
      &:first-child {
        margin-top: 24px ;
      }
      & > button:hover {
        background-color: ${baseTheme.colors.headers};
      }
      &.all button{
        background-color: #fff;
        color: ${baseTheme.colors.bg};
      }
      &.active:not(.all) > button {
        background-color: ${baseTheme.colors.secondary};
        
      }
    }
    hr {
      width: 14px;
      margin-right: auto;
      display: inline-block;
      margin-inline-start: 20px;
      margin-top: 8px;
      margin-bottom: 8px;
      ${baseTheme.keyframes.fadeIn()};
    }
  }
  .menu__controls {
    display: flex;
    padding: 0 18px;
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
    button {
      width: 100%;
      ${baseTheme.keyframes.fadeIn()}
      transition: 0;
      &:nth-of-type(2) {
        margin-left: 12px;
        ${baseTheme.keyframes.fadeIn(0.1)}
      }
    }
  }
  .menu__button-exit {
    position: absolute;
    right: 24px;
    top: 22px;
    display: inline-block;
    padding: 0;
    svg {
      height: 24px;
      width: 24px;
      pointer-events: none;
    }
  }

  .menu__title {
    font-family: ${baseTheme.fonts.secondary};
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 60px;
    text-transform: uppercase;
    color: #FFFFFF;
    
  }

  a {
    box-sizing: border-box;
    padding: 0 18px;
  }

  & + .menu__button {
    ${props => props.isFilters ? 
            "position: static"
            : "position: absolute;" + "width: 24px;" + "height: 16px;"};
    right: 24px;
    top: 22px;
    padding: 0;
    z-index: 10;
    .line {
      width: 24px;
      height: 3px;
      background-color: #fff;
      border-radius: 30px;
      display: inline-block;
      position: absolute;
      transition: .35s;
      will-change: transform;
      
      ${(props) => props.open ?
              "&-1 {  top: 6.75px;  transform: rotate(45deg);}&-2 { top: 6.5px;  right: -200%;}&-3 {  top: 6.75px;  transform: rotate(-45deg);}"
              :
              "&-1 {  top: 0px;  } &-2 {  top: 6.5px; right: 0;}&-3 {  top: 13.5px;}"};
    }
  }
`
