import React, {useState} from 'react';
import MobileMenu from "../MobileMenu/MobileMenu";
import PrimaryButton from "../../General/Buttons/Primary/PrimaryButton";
import FirstLevel from "./Components/FirstLevel";
import SecondLevel from "./Components/SecondLevel";

const MobileFilters = ({filterSelectData, sorting}) => {

    const { filterOptions, activeColumnFilter, resetFilter, showFilters, setShowFilters, handleFilters, setInitialOptions }
        = filterSelectData;

    const [openedFilter, setOpenedFilter] = useState(0);

    const headerMobileMenu = showFilters ? sorting ? openedFilter === 0 ? "Class Type" : "Win Rate" : filterOptions.name  : "filters"

    return (
            <MobileMenu isFilters={true} showFilters={showFilters} filterOptions={filterOptions} setShowFilters={setShowFilters} setInitialOptions={setInitialOptions} header={headerMobileMenu} resetButton={showFilters ? <PrimaryButton content="Reset" className="reset" onClick={openedFilter ? () => resetFilter(false, false) : () => resetFilter(false, true) }/> : <PrimaryButton content="Reset All" className="reset" onClick={() => resetFilter(true)}/>} >
                {!showFilters ?
                    <FirstLevel activeColumnFilter={activeColumnFilter} handleFilters={handleFilters} showFilters={showFilters} sorting={sorting} setShowFilters={setShowFilters} setOpenedFilter={setOpenedFilter}/>
                    : <SecondLevel filterSelectData={filterSelectData} sorting={sorting} openedFilter={openedFilter}/>}
            </MobileMenu>
    );
};

export default React.memo(MobileFilters);