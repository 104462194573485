import React from "react";
import { countClass } from "../../utils/countClass";
import { StyledCard, StyledRating, StyledWinRate } from "./StyledCard";
import config from "../../../src/config";
import getDogAttr from "../../utils/getDogAttr";
import DogeAnalyzerCard from "../../Sections/DogeAnalyzer/DogeAnalyzerCard";
import { Link } from "react-router-dom";

const Dog = ({ secondaryState, dog, active, onClick, isWallet }) => {
  const dogClass = getDogAttr(dog, "class_name");
  const winRate = getDogAttr(dog, "win_rate");
  const inRace = getDogAttr(dog, "inRace");
  const name = getDogAttr(dog, "name");

  return (
    <div>
      <StyledCard
        className={
          "dog__wrapper" +
          (inRace ? " inRace " : "") +
          (secondaryState || isWallet ? " secondaryState " : active ? " active " : "")
        }
      >
        <div className="left__col">
          <div className="flexNoWrap">
            <StyledRating>{dogClass}</StyledRating>
            {/*<StyledWinRate>{winRate}</StyledWinRate>*/}
            <StyledWinRate>{winRate}</StyledWinRate>
          </div>
          <p className="name">{name}</p>

          {isWallet ? (
            <>
              <button className="select" disabled={true}>
                READY TO RACE
              </button>
              <Link className="link-dogeo" to={`/train/${getDogAttr(dog, "id")}`}>
                Enter the Doge-O
              </Link>
            </>
          ) : (
            <button className="select" onClick={() => onClick(dog)}>
              {secondaryState
                ? "LOW CLASS"
                : inRace
                ? "IN RACE"
                : active
                ? "Selected"
                : "Select"}
            </button>
          )}
        </div>
        <div className="dog__nft" style={{ backgroundImage: `url(${dog.image})` }} />
      </StyledCard>
      <DogeAnalyzerCard dogData={dog} />
    </div>
  );
};

export default React.memo(Dog);
