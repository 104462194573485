export function calculateCost(oldStats, newStats) {
  return (
      calculateDtrk(newStats.endurance, oldStats.endurance) +
      calculateDtrk(newStats.agility, oldStats.agility) +
      calculateDtrk(newStats.speed, oldStats.speed)
  );
}

function calculateDtrk(newStat, oldStat) {
  let feeLevel = fetchFeeLevel(oldStat);
  let levelDiff = newStat - oldStat;

  let fees = 0;

  while (levelDiff != 0) {
    let nearestCheckpoint = fetchNearestCheckpoint(oldStat);
    let limit = fetchNearestCheckpoint(oldStat) > newStat ? newStat : nearestCheckpoint;

    let limit_diff = limit - oldStat;

    let floorLevelDiff = limit_diff < 20 ? limit_diff : 20;

    if (floorLevelDiff == 0 && oldStat == nearestCheckpoint) {
      fees = fees + fetchFeeLevel(oldStat + 1);
    } else {
      fees = fees + feeLevel * floorLevelDiff;
    }

    let concFloorLevelDiff = floorLevelDiff === 0 ? 1 : floorLevelDiff;

    oldStat = oldStat + concFloorLevelDiff;
    feeLevel = fetchFeeLevel(oldStat);
    levelDiff = levelDiff - concFloorLevelDiff;
  }

  return fees;
}

function fetchFeeLevel(stat) {
  if (stat > 1 && stat <= 20) {
    return 18;
  } else if (stat >= 21 && stat <= 40) {
    return 36;
  } else if (stat >= 41 && stat <= 60) {
    return 72;
  } else if (stat >= 61 && stat <= 80) {
    return 144;
  } else if (stat >= 81 && stat <= 100) {
    return 255;
  } else {
    return 0;
  }
}

function fetchNearestCheckpoint(stat) {
  if (stat > 1 && stat <= 20) {
    return 20;
  } else if (stat > 20 && stat <= 40) {
    return 40;
  } else if (stat > 40 && stat <= 60) {
    return 60;
  } else if (stat > 60 && stat <= 80) {
    return 80;
  } else if (stat > 80 && stat <= 100) {
    return 100;
  } else {
    return 0;
  }
}
// export function calculateCost(oldStats, newStats) {
//   return (
//     calculateDtrk(newStats.endurance, oldStats.endurance) +
//     calculateDtrk(newStats.agility, oldStats.agility) +
//     calculateDtrk(newStats.speed, oldStats.speed)
//   );
// }
//
// function calculateDtrk(newStat, oldStat) {
//   let feeLevel = fetchFeeLevel(oldStat);
//   let levelDiff = newStat - oldStat;
//
//   let fees = 0;
//
//   while (levelDiff != 0) {
//     let nearestCheckpoint = fetchNearestCheckpoint(oldStat);
//     let limit = fetchNearestCheckpoint(oldStat) > newStat ? newStat : nearestCheckpoint;
//
//     let limit_diff = limit - oldStat;
//
//     let floorLevelDiff = limit_diff < 20 ? limit_diff : 20;
//
//     if (floorLevelDiff == 0 && oldStat == nearestCheckpoint) {
//       fees = fees + fetchFeeLevel(oldStat + 1);
//     } else {
//       fees = fees + feeLevel * floorLevelDiff;
//     }
//
//     let concFloorLevelDiff = floorLevelDiff === 0 ? 1 : floorLevelDiff;
//
//     oldStat = oldStat + concFloorLevelDiff;
//     feeLevel = fetchFeeLevel(oldStat);
//     levelDiff = levelDiff - concFloorLevelDiff;
//   }
//
//   return fees;
// }
//
// function fetchFeeLevel(stat) {
//   if (stat > 1 && stat <= 20) {
//     return 6;
//   } else if (stat >= 21 && stat <= 40) {
//     return 12;
//   } else if (stat >= 41 && stat <= 60) {
//     return 24;
//   } else if (stat >= 61 && stat <= 80) {
//     return 48;
//   } else if (stat >= 81 && stat <= 100) {
//     return 96;
//   } else {
//     return 0;
//   }
// }
//
// function fetchNearestCheckpoint(stat) {
//   if (stat > 1 && stat <= 20) {
//     return 20;
//   } else if (stat > 20 && stat <= 40) {
//     return 40;
//   } else if (stat > 40 && stat <= 60) {
//     return 60;
//   } else if (stat > 60 && stat <= 80) {
//     return 80;
//   } else if (stat > 80 && stat <= 100) {
//     return 100;
//   } else {
//     return 0;
//   }
// }
