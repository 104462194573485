import React from "react";

import {ReactComponent as Coin} from "../../../../assets/img/icons/coin.svg";
import {dogTrain} from "../../../../InfoData/dogTrain";
import ImprovementsItem from "./ImprovementsItem/ImprovementsItem";
import {
    StyledCoins,
} from "../StyledTraining";
import {
    StyledCharTable,
    StyledUpgradeButton,
    StyledUpgradeContent,
    StyledUpgradeText,
} from "./StyledImprovemets";

const Improvements = ({dogeStats, newStats, isAffordable, isStatChanged, trainDoge, dtrkFees}) => {

    return dogeStats && newStats ? (
        <>
            <StyledCharTable>
                {dogTrain.map((feature, index) => (
                    <ImprovementsItem
                        name={feature}
                        feature={dogeStats[feature]}
                        newFeature={newStats[feature]}
                        key={index}
                    />
                ))}
            </StyledCharTable>

            <StyledUpgradeButton
                disabled={!isAffordable || !isStatChanged}
                onClick={trainDoge}
            >
                <StyledUpgradeContent>
                    <StyledUpgradeText>
                        {isStatChanged && !isAffordable ? "Insufficient" : "Upgrade"}
                    </StyledUpgradeText>
                    <StyledCoins>{dtrkFees}<Coin/></StyledCoins>
                </StyledUpgradeContent>
            </StyledUpgradeButton>
        </>
    ) : (
        ""
    );
};

export default React.memo(Improvements);
