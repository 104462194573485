import React from 'react';
import {headers} from "../../../../InfoData/tableFilters";
import MenuItem from "../../MobileMenu/MenuItem/MenuItem";

const FirstLevel = ({sorting, handleFilters, activeColumnFilter, showFilters, setShowFilters, setOpenedFilter}) => {

    const handleDogsFilter = (i) => {
        setShowFilters(true);
        setOpenedFilter(i);
    }

    return (
        sorting ? <>
                <MenuItem className="class-type" onClick={() => handleDogsFilter(0)} i={0} title="Class type" />
                <MenuItem onClick={()=> handleDogsFilter(1)} i={1} title="Winrate" />
                <MenuItem className="sorting" i={2} title={<>Sorting by {sorting}</>} />
            </>
            :
            headers.map((item, i) => (
                <MenuItem className={activeColumnFilter === i && showFilters ? "active" : ""} key={item.name} i={i} onClick={() => handleFilters(item, i)} title={item.name}/>
            ))
    );
};

export default React.memo(FirstLevel);