import React, { createContext } from "react";

export const APIContext = createContext({
    user: null,
    setUser: ()=>{},
    selectDog: ()=>{},
    lobbies: [],
    setLobbies: ()=>{},
    setLobby: ()=>{},
    isMobile: false,
});
