import styled from "styled-components";

export const StyledSpinner = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 11;
  
  
  .spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #2825BF;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 45%;
    left: 45%;
    transform: translate(-50%,-50%);
    z-index: 1;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
