import * as React from "react";
import {
  StyledDAEquipStats,
  StyledEquipmentUL,
  StyledLineProgress,
  StyledStatsItem,
  StyledStatsProgress,
} from "./StyledDAEquipStats";
import getDogAttr from "../../../utils/getDogAttr";
import { useState } from "react";
import SpecialSelector from "../../../uiComponents/SpecialSelector/SpecialSelector";
import Stats from "../../../uiComponents/Cards/Stats/Stats";

const DogAnalyzerEquipStats = ({ dogData }) => {
  const [showTraits, setShowTraits] = useState(true);

  return (
    <StyledDAEquipStats>
      <SpecialSelector
        className="dog-analyzer-tabs"
        showFirst={showTraits}
        content={["traits", "stats"]}
        showItems={(i) => setShowTraits(!i)}
      />

      {showTraits ? (
        <StyledEquipmentUL>
          {dogData.attributes.map((dogAttr, i) => {
            return (
              <li key={i}>
                <span>{dogAttr.trait_type}</span>
                {getDogAttr(dogData, dogAttr.trait_type)}
              </li>
            );
          })}
        </StyledEquipmentUL>
      ) :
          <Stats dogeStats={dogData} noTrain />
      }
    </StyledDAEquipStats>
  );
};

export default React.memo(DogAnalyzerEquipStats);
