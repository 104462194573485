import React from 'react';
import Bar from "./Bar/Bar";
import {dogTrain} from "../../../InfoData/dogTrain";
import {StyledStats} from "./StyledStats";

const Stats = ({dogeStats, newStats, handleChange, noTrain}) => {

    const render = noTrain ? dogeStats : (dogeStats && newStats)

    return render ?
                <StyledStats className={noTrain && "walletStats"}>
                    {dogTrain.map((feature, index) => (
                        <Bar name={feature} feature={dogeStats[feature]} newFeature={newStats && newStats[feature]}
                             handleChange={handleChange} key={index} noTrain={noTrain}/>
                    ))}
                </StyledStats>
                : ""
};

export default React.memo(Stats);