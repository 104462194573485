import React from 'react'
import GlobalStyles from "./styles/globalStyles";
import Header from "./uiComponents/General/Header/Header";
import Routes from "./Routes/Routes";
import Footer from "./uiComponents/General/Footer/Footer";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter basename="/">
      <Header />
      <Routes />
      <Footer />
      <GlobalStyles />
    </BrowserRouter>
  );
};

export default React.memo(App);
