import styled from "styled-components";
import TableHead from "@mui/material/TableHead";
import {baseTheme} from "../../styles/baseTheme";

export const StyledFilterSelect = styled(TableHead)`
  background-color: #fff;
  padding: 14px 20px;

  h3 {
    font-family: ${baseTheme.fonts.primary};
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 8px;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #fff;
  }

  th, td {
    background-color: ${baseTheme.colors.secondary};
    position: relative;
    border-bottom: none;

    &.winRateFilter {
      width: 24%;
      padding-right: 3%;
      padding-left: 20px;
    }

    button {
      &.close {
        border: none;
        cursor: pointer;
        position: absolute;
        right: 23.75px;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        background-color: transparent;
        width: initial;
        height: initial;
        padding: 0;
        font-family: ${baseTheme.fonts.secondary};
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;

        svg {
          margin-left: 7.75px;
        }
      }
    }
  }

  @media ${baseTheme.media.mobile} {
    && {
      position: static;
      display: none;

      tr {
        height: initial;
      }
    }
  }
`