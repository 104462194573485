import styled from "styled-components";
import { baseTheme } from "../../styles/baseTheme";

import dogBg from "../../assets/img/dogs/dogBg.svg";
import Alert from "../../assets/img/dogs/alert.svg";
import CheckMark from "../../assets/img/icons/checkMark.svg";
import InRace from "../../assets/img/dogs/inRace.svg";
import Medal from "../../assets/img/dogs/medal.svg";

export const StyledWrapRatingWinrate = styled.div`
  font-family: ${baseTheme.fonts.primary};
  display: flex;

  &.da-rating-winrate {
    position: absolute;
    top: 14px;
    left: 14px;
  }
`;

export const StyledRating = styled.div`
  ${baseTheme.mixins.classButton("14px")};
  margin-right: 7px;
  white-space: nowrap;
  width: initial;
  display: inline-block;
  padding: 0 12px 20px;
  @media ${baseTheme.media.mobile} {
    font-size: 16px;
    //width: 59px;
    height: 27px;
    padding: 5px 12px 4px 12px;
    height: auto;
    line-height: 1;
  }
`;

export const StyledWinRate = styled.div`
  display: flex;
  align-items: baseline;
  color: white;
  padding-left: 17px;
  background: url(${Medal}) no-repeat 0 38%;

  &:after {
    content: "%";
  }

  svg {
    position: relative;
    top: 3px;
    margin-right: 2px;
  }

  @media ${baseTheme.media.mobile} {
    font-size: 18px;
    align-items: center;
    svg {
      position: static;
    }
  }
`;

export const StyledCard = styled.div`
  width: 100%;
  min-width: 215px;
  margin-right: 12px;
  background-color: #161629;
  border: 1px solid #3c3c54;
  border-bottom-width: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: ${(props) => (props.isTrack ? "flex-start" : "center")};
  ${(props) => (props.isTrack ? "justify-content: space-between;" : "")}
  height: 124px;
  transition: 0.3s opacity;
  margin-bottom: 8px;
  font-family: ${baseTheme.fonts.primary};
  animation: fadeIn 0.3s forwards ease-in-out;

  .left__col {
    width: 50%;
    height: 100%;
    padding: 10px 0;
    box-sizing: border-box;

    .flexNoWrap {
      margin-left: 12px;
      display: flex;
      margin-bottom: 12px;
    }

    .name {
      background: #2d2d42;
      border: 2px solid #3c3c54;
      box-sizing: border-box;
      box-shadow: 4px 4px 4px #1b1b2d;
      border-radius: 0 0 12px 0;
      height: 31px;
      padding: 6px 11px 0;
      font-family: ${baseTheme.fonts.primary};
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 20px;
      text-transform: uppercase;
      color: #ffffff;
      position: relative;
      left: -1px;
      width: fit-content;
      min-width: 100px;
    }

    button {
      cursor: pointer;
      border: none;
      padding: 4px 12px;
      border-radius: 12px 0;
      background-color: ${baseTheme.colors.secondary};
      margin-left: 12px;
      margin-top: 12px;
      font-family: ${baseTheme.fonts.secondary};
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #ffffff;
      transition: 0.3s;
      white-space: nowrap;

      &:hover {
        filter: brightness(120%);
      }
    }

    &.track {
      padding-left: 12px;
      align-items: flex-start;

      .name-track {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        margin-top: 8px;
        text-transform: uppercase;
        white-space: nowrap;
      }

      .status-track {
        margin: 0;
        padding: 0;
      }

      & + .confirm {
        margin: 12px 12px 0 0;
        white-space: nowrap;
      }
    }
  }

  .dog__nft {
    //https://demo.dogetrack.io/static/doges/doge_12562.png
    background-repeat: no-repeat;
    background-position: 10% 40%;
    background-size: 120%;
    width: 50%;
    height: 100%;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: -1px;
      width: calc(100% + 1px);
      height: 100%;
      //background: radial-gradient(10px, rgba(22,22,41, 100%) 0%,rgba(22,22,41,0%) 20px );
      background: linear-gradient(
        to right,
        rgb(22, 22, 41) 0%,
        rgba(22, 22, 41, 0.2) 20px
      );
      //            linear-gradient(to bottom,rgba(22,22,41, 100%) 0%,rgba(22,22,41,0%) 20px ),
      //            linear-gradient(to left,rgba(22,22,41, 100%) 0%,rgba(22,22,41,0%) 20px ),
      //            linear-gradient(to top,rgba(22,22,41, 100%) 0%,rgba(22,22,41,0%) 20px );
    }

    &:before {
      //content: '';
      background: no-repeat url(${dogBg}) center;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  &:hover:not(.secondaryState) {
    border-color: #2e2b73;

    &.active {
      border-color: #2825bf;
    }
  }

  &.active {
    border-color: #2825bf;
    box-shadow: 0 14px 16px rgba(40, 37, 191, 0.35);

    .left__col button {
      background-color: #272736;
      color: #5b5b6b;
      pointer-events: none;
    }
  }

  &.secondaryState {
    position: relative;

    .link-dogeo {
      position: absolute;
      top: 4px;
      right: 4px;
      color: #9898b0;
      border: 1px solid #2d2d3a;
      box-sizing: border-box;
      border-radius: 8px 0;
      background: #363544;
      line-height: 1.1;
      padding: 2px 7px 0;
      z-index: 1;
      opacity: 0.85;
      transition: opacity .4s;
      
      &:hover {
        opacity: 1;
      }
    }
  }

  &.inRace,
  &.secondaryState {
    pointer-events: none;
    background-color: ${(isWallet) => (isWallet ? "#161629" : "transparent")};

    .left__col button {
      pointer-events: none;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      letter-spacing: -0.03em;
      text-transform: uppercase;
      color: #75758b;
      background-color: transparent;
      padding-left: 2px;

      &::before {
        content: url(${Alert});
        position: relative;
        top: 1px;
        margin-right: 5px;
      }
    }
  }

  &.secondaryState {
    ${(isWallet) => (isWallet ? "pointer-events:initial;" : "")}
    .left__col button {
      ${(isWallet) =>
        isWallet
          ? "color: #2BA866; background-color: transparent; padding-right: 0px;"
          : ""};

      &::before {
        ${(isWallet) => (isWallet ? `content: url(${CheckMark})` : "")};
      }
    }
  }

  &.inRace {
    position: relative;

    ${(isWallet) => (isWallet ? "border-bottom-width: 1px;" : "")}
    .left__col button {
      color: #75758b;

      &::before {
        content: url(${Alert});
        position: relative;
        top: 1px;
        margin-right: 5px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      pointer-events: none;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(${InRace});
      z-index: -1;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  @media ${baseTheme.media.mobile} {
    && {
      min-width: 272px;
      width: 100%;
      max-width: 414px;
      &:nth-child(n) {
        margin-right: 0;
      }
      height: 161px;
      background-position-y: center;

      &.secondaryState {
        .select::before {
          display: inline-block;
          width: 11.81px;
          height: 11.81px;
          background-size: contain;
          background-repeat: no-repeat;
          ${(isWallet) =>
            isWallet ? "content: '';" + `background-image: url(${CheckMark});` : ""}
        }
      }

      .left__col {
        padding: ${(isTrack) => (isTrack ? "" : "12px 0 18px")};

        .name {
          width: 100%;
          font-size: 32px;
          padding: 14px 0px 0px 16px;
          height: 48px;
        }

        .select {
          font-size: 14px;
          margin-top: 16px;
        }

        &.track {
          & > svg,
          & > img {
            width: 70%;
            height: 42%;
          }

          .name-track {
            font-size: 32px;
            margin-top: 16%;
          }

          .status-track {
            font-size: 13px;
            margin-top: 10px;
          }
        }
      }

      & > svg {
        width: 100%;
      }
    }
  }
`;

// import styled from "styled-components";
// import {baseTheme} from "../../styles/baseTheme";
//
// import dogBg from "../../assets/img/dogs/dogBg.svg";
// import Alert from "../../assets/img/dogs/alert.svg";
// import CheckMark from "../../assets/img/icons/checkMark.svg";
// import InRace from "../../assets/img/dogs/inRace.svg";
//
// export const StyledCard = styled.div`
//   width: 23%;
//   min-width: 215px;
//   margin-right: 12px;
//   background-color: #161629;
//   border: 1px solid #3C3C54;
//   border-bottom-width: 4px;
//   box-sizing: border-box;
//   display: flex;
//   align-items: ${props => props.isTrack ? "flex-start" : "center"};
//   ${props => props.isTrack ? "justify-content: space-between;" : ""}
//   height: 124px;
//   transition: .3s opacity;
//   margin-bottom: 8px;
//   font-family: ${baseTheme.fonts.primary};
//   animation: fadeIn 0.3s forwards ease-in-out;
//
//   .left__col {
//     width: 45%;
//     height: 100%;
//     padding: 10px 0;
//     box-sizing: border-box;
//
//     .flexNoWrap {
//       display: flex;
//       margin-bottom: 12px;
//
//       .rating {
//         ${baseTheme.mixins.classButton("14px")};
//         margin-right: 7px;
//         margin-left: 12px;
//         white-space: nowrap;
//         width: initial;
//         padding-right: 8px;
//         padding-top: 0;
//         padding-bottom: 20px;
//       }
//
//       .winRate {
//         display: flex;
//
//         svg {
//           position: relative;
//           top: 3px;
//           margin-right: 2px;
//         }
//       }
//     }
//
//     .name {
//       background: #2D2D42;
//       border: 2px solid #3C3C54;
//       box-sizing: border-box;
//       box-shadow: 4px 4px 4px #1B1B2D;
//       border-radius: 0 0 12px 0;
//       width: 92px;
//       height: 31px;
//       padding: 6px 14px 0;
//       font-family: ${baseTheme.fonts.primary};
//       font-style: normal;
//       font-weight: normal;
//       font-size: 20px;
//       line-height: 20px;
//       text-transform: uppercase;
//       color: #FFFFFF;
//       position: relative;
//       left: -1px;
//     }
//
//     button {
//       cursor: pointer;
//       border: none;
//       padding: 4px 12px;
//       border-radius: 12px 0;
//       background-color: ${baseTheme.colors.secondary};
//       margin-left: 12px;
//       margin-top: 12px;
//       font-family: ${baseTheme.fonts.secondary};
//       font-style: normal;
//       font-weight: 500;
//       font-size: 12px;
//       line-height: 16px;
//       text-align: center;
//       letter-spacing: -0.03em;
//       color: #FFFFFF;
//       transition: .3s;
//       white-space: nowrap;
//       &:hover {
//         filter: brightness(120%);
//       }
//     }
//     &.track {
//       padding-left: 12px;
//       align-items: flex-start;
//       .name-track {
//         font-style: normal;
//         font-weight: 500;
//         font-size: 20px;
//         line-height: 22px;
//         margin-top: 8px;
//         text-transform: uppercase;
//         white-space: nowrap;
//       }
//       .status-track {
//         margin: 0;
//         padding: 0;
//       }
//       & + .confirm {
//         margin: 12px 12px 0 0;
//       }
//     }
//   }
//   .dog__nft {
//     background-image: url(${dogBg});
//     background-repeat: no-repeat;
//     background-position-x:center;
//     width: 50%;
//     svg {
//       margin: 0 auto;
//       width: 100%;
//     }
//   }
//   &:hover:not(.secondaryState) {
//     border-color: #2e2b73;
//
//     &.active {
//       border-color: #2825BF;
//     }
//   }
//
//   &.active {
//     border-color: #2825BF;
//     box-shadow: 0 14px 16px rgba(40, 37, 191, 0.35);
//
//     .left__col button {
//       background-color: #272736;
//       color: #5B5B6B;
//       pointer-events: none;
//     }
//   }
//
//   &.inRace, &.secondaryState {
//     pointer-events: none;
//     background-color: ${(isWallet) => isWallet ? "#161629" : "transparent"};
//
//     .left__col button {
//       pointer-events: none;
//       font-style: normal;
//       font-weight: 600;
//       font-size: 10px;
//       line-height: 14px;
//       text-align: center;
//       letter-spacing: -0.03em;
//       text-transform: uppercase;
//       color: #75758B;
//       background-color: transparent;
//       padding-left: 2px;
//
//       &::before {
//         content: url(${Alert});
//         position: relative;
//         top: 1px;
//         margin-right: 5px;
//       }
//     }
//   }
//
//   &.secondaryState {
//     ${(isWallet) => isWallet ? "pointer-events:initial;" : ""}
//     .left__col button {
//       ${(isWallet) => isWallet ? "color: #2BA866; background-color: transparent; padding-right: 0px;" : ""};
//       &::before {
//         ${(isWallet) => isWallet ? `content: url(${CheckMark})` : ""};
//       }
//     }
//   }
//   &.inRace {
//     position: relative;
//     ${(isWallet) => isWallet ? "border-bottom-width: 1px;" : ""}
//     .left__col button {
//       color: #75758B;
//       &::before {
//         content: url(${Alert});
//         position: relative;
//         top: 1px;
//         margin-right: 5px;
//       }
//     }
//     &::after {
//       content: "";
//       position: absolute;
//       pointer-events: none;
//       left: 0;
//       top: 0;
//       width: 100%;
//       height: 100%;
//       background-image: url(${InRace});
//       background-repeat: no-repeat;
//       background-size: cover;
//     }
//   }
//
//   @media ${baseTheme.media.mobile} {
//     min-width: 272px;
//     height: 161px;
//     background-position-y: center;
//     .left__col {
//       padding: 12px 0 18px;
//       .flexNoWrap {
//         .rating {
//           font-size: 16px;
//           width: 59px;
//           height: 27px;
//         }
//         .winRate {
//           font-size: 18px;
//           align-items: center;
//           svg {
//             position: static;
//           }
//         }
//       }
//         .name {
//           width: 100%;
//           font-size: 32px;
//           padding: 14px 0px 0px 16px;
//           width: 118px;
//           height: 48px;
//         }
//       .select {
//         font-size: 16px;
//         padding: 8px 20px;
//       }
//       &.track {
//         & > svg,& > img {
//               width: 70%;
//           height: 42%;
//         }
//         .name-track {
//           font-size: 32px;
//           margin-top: 16%;
//         }
//         .status-track {
//           font-size: 13px;
//           margin-top: 10px;
//         }
//       }
//     }
//     & > svg {
//       width: 100%;
//     }
//   }
// `
