import React, {useEffect, useState} from 'react';
import {StyledMobileMenu} from "./StyledMobileMenu";

import {ReactComponent as ArrRight} from "../../../assets/img/icons/ArrDown.svg";
import {ReactComponent as FilterSVG} from "../../../assets/img/icons/filter.svg";
import PrimaryButton from "../../General/Buttons/Primary/PrimaryButton";

const MobileMenu = ({children, header, isFilters, showFilters, setShowFilters, resetButton, setInitialOptions, filterOptions}) => {

    const [wasOpened, setWasOpened] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const [initialFilters, setInitialFilters] = useState({});

    const closeMenu = (e, isNavigation, isResetFilters) => {
        const TAG = e?.target?.tagName ? e?.target?.tagName : "A";
        if(TAG === "BUTTON" || TAG === "SPAN" || e?.target?.children[0]?.tagName === "BUTTON") return;
        if(isFilters) {
            if (showFilters ) setShowFilters(false);
            else if (isNavigation) {
                setIsOpened(false)
                isResetFilters ? resetCloseFilters() : null;
            }
        }
        else if (TAG === "A" ) setIsOpened(false);
    }

    const handleMenu = () => isOpened ? closeMenu() : openMenu();

    const openMenu = () => {
        setWasOpened(true);
        setIsOpened(true);
    }

    const resetCloseFilters = () => {
        setInitialOptions(initialFilters);
        setIsOpened(false);
    }

    useEffect(()=> {
        isOpened ?
            document.body.children[1].classList.add("overflow")
            : document.body.children[1].classList.remove("overflow");

        return () => isFilters ? setShowFilters(false) : null
    }, [isOpened])

    useEffect(()=>{
        if(isFilters) setInitialFilters(filterOptions);
    }, [])

    return (
        <>
            <StyledMobileMenu open={isOpened} isFilters={isFilters} showFilters={showFilters} className={wasOpened ? " wasOpened" : ""}>
                <h3 className="menu__header">
                    <a onClick={(e) => closeMenu(e, true, true)}>
                        <ArrRight/>
                        {header}
                    </a>
                    {resetButton}
                </h3>
                <div onClick={(e) => closeMenu(e)} className="menu__list">
                    {isOpened ? children : null}
                </div>
                {/* Handling buttons   */}
                <div className="menu__controls">
                    {isFilters ?
                        showFilters ?
                            <>
                                <PrimaryButton content="back" className="back" onClick={() => closeMenu("",true )}/>
                                <PrimaryButton content="apply" className="apply" onClick={() => setIsOpened(false)}/>
                            </>
                            : <PrimaryButton content="apply" className="apply" onClick={() => setIsOpened(false)}/>
                        : null
                    }
                </div>
            </StyledMobileMenu>
            <div className="menu__button" onClick={()=> handleMenu()}>
                {isFilters ? <PrimaryButton content={(<>filters <FilterSVG/></>)} className="mobile__filters" />
                        : <><span className="line line-1"/><span className="line line-2"/><span className="line line-3"/></>}
            </div>
        </>
    );
};

export default React.memo(MobileMenu);