import React, { useEffect, useState } from "react";
import Spinner from "../uiComponents/Helpers/Spinner/spinner";
import CreateLobby from "../uiComponents/Modals/CreateLobby/CreateLobby";
import TitleWallet from "../Sections/Wallet/Title/TitleWallet";
import UserData from "../Sections/Wallet/UserData/UserData";
import Filters from "../Sections/Wallet/Filters/Filters";
import useAPI from "../Hooks/useAPI";

import { StyledContainer } from "../uiComponents/General/Container/StyledContainer";
import ReturnLobby from "../uiComponents/Modals/ReturnLobby/ReturnLobby";

import { useWallet } from "@gokiprotocol/walletkit";
import { getUserNFTs } from "../InfoData/fetchUserNFT";

const Wallet = ({ setShowPopUp }) => {
  const { user, setUser, isMobile, lobbies } = useAPI();
  const { wallet, connection } = useWallet();
  const [showDogs, setShowDogs] = useState(true);
  const [showFilters, setShowFilters] = useState(0);
  const [chosenLobby, setChooseLobby] = useState(false);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    async function fetchNFTs() {
      setSpinner(true);
      const newUserData = await getUserNFTs(wallet.publicKey, connection);

      for (let userDog of newUserData.userDogs) {
        let finnedDog = false;

        for (let lobby of lobbies) {
          finnedDog = lobby.players.find((player) => player.solana_id === userDog._id);
          if (finnedDog) userDog.inRace = true;
        }
      }
      setSpinner(false);
      setUser(newUserData);
      setShowPopUp(false);
    }

    wallet?.publicKey && fetchNFTs();
  }, [wallet?.publicKey]);

  const [filters, setFilters] = useState({
    winRate: [0, 100],
    event_type: [],
    sorting: -1,
    search: "",
  });

  const setFilter = (key, val) => {
    setFilters((filters) => ({ ...filters, [key]: val }));
  };

  useEffect(() => {
    renderData();
  }, [showDogs]);

  const renderData = () => (showDogs ? renderDogs() : renderTracks());

  const getCurrentTracks = () => {
    const newData = user;
    newData.tracks = newData.tracks?.map((track) => {
      const finnedTrack = lobbies.find((lobby) => {
        return lobby.track.track_solana_id === track.track_solana_id;
      });
      if (finnedTrack) track.inRace = true;

      return track;
    });
    return newData;
  };

  const renderTracks = () => {
    let newUser = getCurrentTracks();
    setUser(newUser);
    return newUser?.tracks;
  };

  const renderDogs = () => {
    return user?.userDogs;
  };

  return (
    <StyledContainer>
      <TitleWallet user={wallet} spinner={spinner} />

      <CreateLobby chosenLobby={chosenLobby} setChooseLobby={setChooseLobby} />

      <ReturnLobby
        chosenLobby={chosenLobby}
        setChooseLobby={setChooseLobby}
        lobbies={lobbies}
      />

      {spinner ? (
        <Spinner />
      ) : wallet?.publicKey && user.userDogs ? (
        <>
          <Filters
            className={showFilters ? "filters-opened" : ""}
            setShowFilters={setShowFilters}
            showFilters={showFilters}
            setFilters={setFilters}
            setFilter={setFilter}
            isMobile={isMobile}
            filters={filters}
            isTrack={!showDogs}
          />
          <UserData
            setChooseLobby={setChooseLobby}
            className={showFilters ? "filters-opened" : ""}
            showDogs={showDogs}
            filters={filters}
            setUser={setUser}
            user={user}
            data={renderData()}
          />
        </>
      ) : null}

    </StyledContainer>
  );
};

export default React.memo(Wallet);
