import React from 'react';
import {StyledSuccess} from "./StyledSuccess";
import CSSTransition from "react-transition-group/CSSTransition";
import {ReactComponent as Exit} from "../../../assets/img/icons/exit.svg";
import PrimaryButton from "../../General/Buttons/Primary/PrimaryButton";
import {useHistory} from "react-router-dom";

const Success = ({success, setSuccess}) => {

    const close = () => setSuccess(false);

    const history = useHistory();

    const navigateToLobby = () => {
        history.push(`/id=${success._id}`);
        close();
    }

    return (
        <CSSTransition in={!!success && success !== "error" } classNames="fade" timeout={400} unmountOnExit >
            <StyledSuccess>
                <div className="overlay" onClick={()=>close()}/>
                <div className="success">
                    <Exit onClick={()=>close()} className="success-exit"/>
                    <h2 className="success-title">Lobby created successfully</h2>
                    <PrimaryButton content="Close" className="close" onClick={()=>close()}/>
                    <PrimaryButton content="View" onClick={()=>navigateToLobby()}/>
                </div>
            </StyledSuccess>
        </CSSTransition>
    );
};

export default React.memo(Success);
