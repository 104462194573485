import styled from "styled-components";
import {baseTheme} from "../../../styles/baseTheme";
import House from "../../../assets/img/bg/House-8.png";
import React from "react";

export const StyledTraining = styled.div`
  font-family: ${baseTheme.fonts.secondary};
  display: flex;
  justify-content: center;
  //margin: 0 auto 100px;
  width: 730px;
  height: 380px;
  background: linear-gradient(90deg, #252531 0%, #21212C 50.09%, #252531 100%);
  //border: 14px solid #171721;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: -11%;
  margin: auto;
  transform: scale(1);
  @media(max-width: 1000px), (max-height: 900px) {
    transform: scale(0.9);
  }
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
  //overflow: hidden;

  && > * {
    width: 50%;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    padding: 0;

    &:nth-child(2) {
      border-left: 2px solid rgba(23, 23, 33, 0.3);
      background: linear-gradient(90deg, #252531 0%, #21212C 50.09%, #252531 100%);
    }
  }

    //@media ${baseTheme.media.mobile} {
  @media (max-width: 780px) {
    flex-direction: column;
    width: 100%;
    height: fit-content;
    max-width: 365px;
    && > * {
      width: 100%;
      //height: 337px;
      &:nth-child(2) {
        border-left: none;
        border-top: 2px solid rgba(23, 23, 33, 0.3);
        height: 337px;
      }
    }
  }
`


export const StyledCoins = styled.span`
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
  font-family: ${baseTheme.fonts.primary};
  color: #FCC417;

  svg {
    margin-left: 5px;
  }
`

export const StyledStatContainer = styled.div`
  height: ${({noTrain}) => noTrain ? "initial" : "50%"};
  width: 100%;
`

export const StyledStatTitle = styled.h2`
  background-color: #30303E;
  font-family: ${baseTheme.fonts.primary};
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.05em;
  padding: 8px 20px 6px;
  color: #9898B0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledStatContent = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: ${({noTrain}) => noTrain ? "initial" : "calc(100% - 40px)"};
  box-sizing: border-box;
  animation: fadeIn 0.5s forwards ease-in-out;
  
  
  &.stats__container {
    padding: ${({noTrain}) => noTrain ? 0 : "10px 20px"};
    ${({noTrain}) => noTrain && "min-height: 219px"}
  }

  &.content__improvements {
    flex-wrap: nowrap;
  }
  
  @media(max-width: 380px) {
    padding: 15px;
  }
`

export const StyledLoading = styled.img`
  display: block;
  margin: 0 auto;
  max-height: 380px;
  max-width: 380px;
  width: 100%;
  animation: fadeOut 0.5s 3.8s ease-in-out forwards;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const StyledUpdateLoading = styled.video`
  display: block;
  margin: 0 auto;
  max-height: 380px;
  max-width: 380px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const StyledBgContainer = styled.div`
  width: 730px;
  height: 380px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: -1;
`

export const StyledBg = styled.img`
 &&{
  position: absolute;
   transform: translate(-50.2%,-54.5%);
   width: 1481px;
   height: 840px;
   top: 50%;
   left: 50%;
   z-index: -1;
   @media(min-width: 1481px), (min-height: 840px) {
     display: none;
   }
}
`



