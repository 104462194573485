import styled from "styled-components";
import {baseTheme} from "../../../styles/baseTheme";

export const StyledFilters = styled.div`
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    label {
      position: relative;
      margin-right: 24px;

      input {
        width: 251px;
        height: 36px;
        font-family: ${baseTheme.fonts.secondary};
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        border-radius: 12px 0;
        padding: 11px 19px;
        background-color: transparent;
        border: 1px solid ${baseTheme.colors.borders};
        color: #fff;
        position: relative;
        box-sizing: border-box;
        transition: border 0.4s;

        &::placeholder {
          color: ${baseTheme.colors.borders};
        }

        &:focus, &:active {
          outline: none;
        }
        
        &:focus {
          border-color: #2825BF;
        }
      }

      svg {
        position: absolute;
        right: 10.72px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }

    .filter {
      cursor: pointer;
      border: none;
      background-color: transparent;
      font-family: ${baseTheme.fonts.primary};
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #BDBCCE;
      svg {
        margin-left: 6.44px;
        position: relative;
        top: 2px;
      }

      &.active, &:hover {
        color: ${baseTheme.colors.secondary};

        svg path {
          fill: ${baseTheme.colors.secondary};
        }
      }
    }

    .right {
      position: relative;

      .sorting {
        background: #11111F;
        border: 1px solid ${baseTheme.colors.borders};
        box-sizing: border-box;
        border-radius: 12px 0;
        width: 162px;
        height: 36px;
        padding: 0 17px 1px;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        color: #63637C;
        font-family: ${baseTheme.fonts.secondary};
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }

      .variants {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        z-index: 2;
        top: 42px;
        width: 100%;
        transition: .3s;
        padding-left: 0;
        margin: 0;

        li {
          background: #11111F;
          border: 1px solid ${baseTheme.colors.borders};
          box-sizing: border-box;
          list-style: none;
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 15px;
          color: #FFFFFF;
          font-family: ${baseTheme.fonts.secondary};
          padding: 11px 12px;
          border-top: none;
          cursor: pointer;
          transition: .3s;

          &:first-child {
            border-top: 1px solid ${baseTheme.colors.borders};
          }

          &:hover, &.active {
            background-color: #202038;
          }
        }
      }

      &.active {
        .sorting {
          svg {
            transform: rotate(180deg);
            pointer-events: none;
          }
        }

        .variants {
          opacity: 1;
          pointer-events: initial;
        }
      }
    }
  }

  & + table {
    transition: .3s;
    position: relative;
    top: -97px;
    clip-path: inset(100% 0 0 0);
    pointer-events: none;
  }

  &.filters-opened {
    & + table {
      pointer-events: initial;
      position: relative;
      top: 0;
      clip-path: inset(0 0 0 0);
    }
  }
  
  @media ${baseTheme.media.mobile} {
    .top-bar {
      .left {
        width: 52%;
      }
      label {
        margin-right: 0;

        input {
          box-sizing: content-box;
          height: 22px;
          width: 100%;
          padding: 7px 13px;
        }
      }
      .menu__button {
        z-index: 0;
        button {
          background-color: ${baseTheme.colors.headers};
          color: #fff;
          svg path {
            fill: #fff;
          }
        }
      }
    }
  }
`
