import React, { useEffect, useRef } from "react";
import { StyledNft, StyledDogNumber, StyledName } from "./StyledNft";
import { ReactComponent as Coin } from "../../../../assets/img/icons/coin.svg";
import { StyledCoins } from "../StyledTraining";
import { StyledDAViewerName } from "../../../DogeAnalyzer/DogAnalyzerViewer/StyledDAViewer";
import getDogAttr from "../../../../utils/getDogAttr";
import useAPI from "../../../../Hooks/useAPI";

const Nft = ({ dogeId, tokenBalance, userDoge, setLoading }) => {
  const handleOnLoad = () => {
    userDoge?.image && setTimeout(setLoading, 400, "loadedImg", true);
  };

  const user = useAPI()
  return !userDoge ? null : (
    <StyledNft className="dog__nft">
      <img src={userDoge?.image} alt="dog" onLoad={handleOnLoad} />
      {/*<DogTrainBg className="dog__nft-bg" style={{opacity: 0.1}}/>*/}
      <StyledCoins>
        {tokenBalance ? tokenBalance : 0}
        <Coin />
      </StyledCoins>

      <StyledDAViewerName>
        <div className="name">{getDogAttr(userDoge, 'name')}</div>
        <div className="under-name">
          <span className="dog-id">#{dogeId} </span>
          {getDogAttr(userDoge, "sex")}
        </div>
      </StyledDAViewerName>
    </StyledNft>
  );
};

export default React.memo(Nft);
