import styled from "styled-components";
import {baseTheme} from "../../../styles/baseTheme";

export const StyledUserData = styled.div`
  color: #fff;
  position: relative;
  top: -102px;
  transition: .3s;
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  //padding-bottom: 166px;
  padding-bottom: 0;
  //max-height: calc(100vh - 385px);
  //overflow: auto;
  & > div {
    width: calc(25% - 7px);
    margin-right: 9px;
    margin-bottom: 20px;
    
    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }
  &.filters-opened {
    top: 0;
  }
  @media (max-width: 927px) {
    padding-bottom: 236px;
    & > div {
      width: calc(33.3% - 7px);
      margin-right: 10.5px;
      &:nth-of-type(3n) { margin-right: 0; }
      &:nth-of-type(4n) { margin-right: 9px; }
    }
  }
  @media ${baseTheme.media.mobile} {
    position: static;
    justify-content: space-between;
    & > div {
      width: calc(50% - 8px);
      max-width: initial;
      &:nth-of-type(2n) { margin-right: 0; }
      &:nth-of-type(3n) { margin-right: 9px; }
      &:nth-of-type(4n) { margin-right: 9px; }
    }
    .dog-wrapper {
      max-width: 346px;
      width: 100%;
      
      .left-col .select {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        padding-left: 0;
        padding-right: 0;
        white-space: nowrap;
      }
      margin-bottom: 8px;
    }
  }

  @media (max-width: 600px) {
    position: static;
    justify-content: space-between;
    & > div {
      width: calc(100%);
      margin-right: 0;
      
      > div {
        margin-left: auto !important;
        margin-right: auto !important; 
      }
    }
  }
`

