import styled from "styled-components";
import {baseTheme} from "../../../styles/baseTheme";

export const StyledCreateLobby = styled.form`
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  .create__lobby-container {
    //overflow-y: scroll;
    position: relative;
    margin: auto;
    width: 322px;
    height: fit-content;
    background: #090922;
    border: 1px solid #2825BF;
    box-sizing: border-box;
    border-radius: 22px 0;
    z-index: 10;
    color: #fff;
    padding: 26px 24px;
    font-family: 'Teko',sans-serif;
    .create__lobby-exit {
      width:17.01px;
      height: 17.01px;
      position: absolute;
      right: 11.5px;
      top: 11.5px;
      cursor: pointer;
    }
    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 46px;
      text-transform: uppercase;
      color: #FFFFFF;
      margin: 0;
      margin-bottom: 16px;
    }
    .create__lobby-content {
      &.error {
        input, .dropdown,  .form__button, .counter {
          border: 1px solid ${baseTheme.colors.alert};
        }
      }
    }
    .form__button {
      margin-right: 4px;
      margin-bottom: 6px;
    }
    .create__lobby {
      margin-left: auto;
      display: block;
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.25);
  }
`