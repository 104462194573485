import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { PublicKey, Connection } from '@solana/web3.js';

import {
  Edition,
  MasterEdition,
  Metadata,
  MetadataKey,
} from '@metaplex-foundation/mpl-token-metadata';
import axios from 'axios';
import config from '../config';

async function getMetadata(connection, mint) {
  const metadataPDA = await Metadata.getPDA(mint);
  const { data: metadata } = await Metadata.load(connection, metadataPDA).catch(
      () => {
        return { data: null };
      }
  );

  if (metadata) {
    try {
      const res = metadata.data.uri ? await axios.get(metadata.data.uri) : null;
      return {
        metadataPDA: metadataPDA.toString(),
        onChainMetadata: metadata,
        arweaveMetadata: res?.data || null,
      };
    } catch (err) {
      return {
        metadataPDA: metadataPDA.toString(),
        onChainMetadata: metadata,
        arweaveMetadata: null,
      };
    }
  }

  return null;
}

async function getMasterEdition(connection, mint) {
  const masterEditionPDA = await Edition.getPDA(mint);
  const editionAccountInfo = await connection.getAccountInfo(masterEditionPDA);

  if (editionAccountInfo) {
    const key = editionAccountInfo?.data[0];
    let masterEditionData;

    switch (key) {
      case MetadataKey.MasterEditionV1:
      case MetadataKey.MasterEditionV2:
        const { data } = new MasterEdition(
            masterEditionPDA,
            editionAccountInfo
        );
        masterEditionData = data;
        break;
      default:
        masterEditionData = undefined;
        break;
    }

    return {
      masterEditionPDA: masterEditionPDA.toString(),
      masterEditionData,
    };
  }

  return null;
}

export async function getUserNFTs(user, connection) {
  // const connection = new Connection(config.RPC_URL);
  const { value: tokens } = await connection.getParsedTokenAccountsByOwner(
      user,
      {
        programId: TOKEN_PROGRAM_ID,
      }
  );

  const filteredTokens = await Promise.all(
      tokens
          .filter((token) => {
            const { tokenAmount } = token.account.data.parsed.info;
            return tokenAmount.decimals === 0 && tokenAmount.uiAmount === 1;
          })
          .map(async (t) => ({
            tokenAccount: t.pubkey.toString(),
            mint: new PublicKey(t.account.data.parsed.info.mint).toString(),
            metadata: await getMetadata(
                connection,
                t.account.data.parsed.info.mint
            ),
            masterEdition: await getMasterEdition(
                connection,
                t.account.data.parsed.info.mint
            ),
          }))
  );

  const userNFTs = filteredTokens.filter((filteredToken) => {
    return (
        filteredToken.mint &&
        filteredToken.metadata?.arweaveMetadata &&
        filteredToken.metadata?.onChainMetadata
    );
  });

  const userTracks = userNFTs
      .filter((nft) => config.TRACK_MINT_LIST.includes(nft.mint))
      .map((nft) => {
        const location = nft.metadata.arweaveMetadata?.attributes.find(
            (a) => a.trait_type === 'Location'
        ) || { trait_type: 'Location', value: 'DogeHeaven' };

        const event_type = nft.metadata.arweaveMetadata?.attributes.find(
            (a) => a.trait_type === 'Max Class'
        ) || { trait_type: 'Max Class', value: '1' };

        return {
          ...nft.metadata.arweaveMetadata,
          edition: nft.metadata.arweaveMetadata.name.replace(
              'Genesis Track #',
              ''
          ),
          track_solana_id: nft.mint,
          location: location.value,
          event_type: event_type.value,
          metadata: nft.metadata.metadataPDA,
          trackAddress: nft.tokenAccount,
        };
      });

  const userDogs = await Promise.all(
      userNFTs
          .filter((nft) => config.DOGE_MINT_LIST.includes(nft.mint))
          .map(async ({ metadata, mint, tokenAccount }) => {
            const edition = metadata.arweaveMetadata.name.replace(
                'Genesis Doge #',
                ''
            );

            const {
              data: {
                body: {
                  endurance,
                  agility,
                  speed,
                  count_of_win,
                  count_of_races,
                  dogName,
                  solana_id,
                },
              },
            } = await axios.get(`${config.SERVER_ADDRESS}/api/metadata/${edition}`);

            return {
              ...metadata.arweaveMetadata,
              speed,
              endurance,
              agility,
              count_of_win,
              count_of_races,
              mint: mint,
              metadata: metadata.metadataPDA,
              dogeAddress: tokenAccount,
              dogName,
              solana_id,
            };
          })
  );

  localStorage.setItem(
      "userDoges",
      JSON.stringify({ userDoges: userDogs, cacheUpdatedAt: Math.floor(Date.now() / 1000) })
  );

  return {
    _id: 943,
    lobbyID: null,
    selectDog: null,
    userDogs,
    tracks: userTracks,
  };
}

export async function getDtrkBalance(walletAddress, connection) {
  try {
    /* Logic starts here */
    const { value } = await connection.getParsedTokenAccountsByOwner(
        walletAddress,
        {
          mint: config.DTRK_MINT,
        }
    );

    if (value.length === 0) return 0;

    if (value[0]?.account?.data?.parsed?.info?.tokenAmount) {
      return {
        balance: value[0]?.account?.data?.parsed?.info?.tokenAmount.uiAmount,
        address: value[0].pubkey,
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}
