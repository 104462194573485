import styled from "styled-components";

export const StyledStats = styled.div`
  display: contents;
  &.walletStats {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 217px;
    animation: fadeIn 0.5s forwards ease-in-out;
    .stat-value {
      div {
        color: #fff;
      }
    }
  }
`