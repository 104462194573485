import React from 'react';
import MenuItem from "../../MobileMenu/MenuItem/MenuItem";
import PrimaryButton from "../../../General/Buttons/Primary/PrimaryButton";
import WinRateSlider from "../../../FilterSelect/WinRateSlider/WinRateSlider";

const SecondLevel = ({filterSelectData, sorting, openedFilter}) => {

    const { filterOptions, activeFilterItems, activeColumnFilter, resetFilter, setFilter, checkActiveVariantFilter, handleFilterVariants }
        = filterSelectData;

    return (
        sorting ?
            openedFilter === 0 ?
                <>
                    <MenuItem className={"filter-item " + (!filterOptions?.index ? "active " : "") + "all"} activeClassName="active-filter" onClick={() => resetFilter()} i={0} title={<PrimaryButton content="ALL" className="filter-button"/>}/>
                    <hr/>
                    {activeFilterItems.map((item, filterColumn) => (
                        <MenuItem className={"filter-item " + checkActiveVariantFilter(item, activeColumnFilter)} activeClassName="active-filter" onClick={() => handleFilterVariants(item, activeColumnFilter)} i={filterColumn + 1} title={<PrimaryButton content={item?.name} className="filter-button"/>} key={filterColumn} />
                    ))}
                </>
                : <MenuItem className="filter-item slider" title={<WinRateSlider filters={filterOptions} setFilter={setFilter} className="mobile" noHeader={true}/>}/>
            :
            <>
                <MenuItem className={"filter-item " + (!filterOptions?.index ? "active " : "") + "all"} activeClassName="active-filter" onClick={() => resetFilter()} i={0} title={<PrimaryButton content="ALL" className="filter-button-white"/>}/>
                <hr/>
                {activeFilterItems.map((item, filterColumn) => (
                    <MenuItem className={"filter-item " + checkActiveVariantFilter(item, activeColumnFilter)} onClick={() => handleFilterVariants(item, activeColumnFilter)} activeClassName="active-filter" i={filterColumn + 1} key={filterColumn} title={<PrimaryButton content={item?.name} className="filter-button"/>} />
                ))}
            </>
    );
};

export default React.memo(SecondLevel);