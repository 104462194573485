import {countWinRate} from "./countWinRate";

export const sortDogs = (data, {sorting}) => {


    const sortWinRateNeg = (a, b) => countWinRate(a) < countWinRate(b) ? -1 : countWinRate(a) > countWinRate(b) ? 1 : 0;

    const sortWinRatePos = (a, b) => countWinRate(a) > countWinRate(b) ? -1 : countWinRate(a) < countWinRate(b) ? 1 : 0;

    const sort = (a,b) => {
        switch (sorting) {
            case 0:
                return sortPosi('name',a,b);
            case 1:
                return sortNeg('name',a,b);
            case 2:
                return sortWinRatePos(a,b);
            case 3:
                return sortWinRateNeg(a,b);
            case 4:
                return sortNeg('class',a,b);
            case 5:
                return sortPosi('class',a,b);
        }
    }

    return [...data].sort((a,b) => sort(a,b))
}

const sortPosi = (key, a, b) => a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;

const sortNeg = (key, a, b) => a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0;

export const sortTracks = (data, {sorting}) => {

    const sort = (a,b) => {
        switch (sorting) {
            case 0:
                return sortPosi('name',a,b);
            case 1:
                return sortNeg('name',a,b);
            case 2:
                return sortNeg('inRace',a,b);
            case 3:
                return sortPosi('inRace',a,b);
        }
    }

    return [...data].sort((a,b) => sort(a,b))
}

