import React from 'react';
import PrimaryButton from "../General/Buttons/Primary/PrimaryButton";
import {StyledCard} from "./StyledCard";

import {ReactComponent as TRACK} from "../../assets/img/track/track.svg";


const Track = ({track, onClick}) => {

    const isLobbyStayed24Hours = true;

    return (
        <StyledCard className={"track__wrapper secondaryState " + (track?.inRace ? " inRace " : "")} isTrack={true} isWallet={true}>
            <div className="left__col track">
                {track.nft ? track.nft :  <TRACK/>}
                <p className="name-track">{track.name}</p>
                <button className="status-track" disabled={track.inRace}>
                    {track.inRace ? "used for races" : "available for races"}
                </button>
            </div>

            <PrimaryButton content={track.inRace ? "Return Track" : "Create Lobby"} className={"confirm" + (track.inRace ? " return" : "")} isDisabled={track.inRace && !isLobbyStayed24Hours} onClick={()=>onClick(track)}/>

        </StyledCard>
    );
};

export default React.memo(Track);
