import React from 'react';
import {StyledStatContainer, StyledStatContent, StyledStatTitle} from "../StyledTraining";

const EquipStatsItem = ({title, className, children}) => {

    return <StyledStatContainer>

        <StyledStatTitle>
            {title}
        </StyledStatTitle>

        <StyledStatContent className={className}>
            {children}
        </StyledStatContent>

    </StyledStatContainer>
};

export default React.memo(EquipStatsItem);