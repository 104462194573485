import { createGlobalStyle } from 'styled-components'
import {baseTheme} from "./baseTheme";
import BG from "../assets/img/bg.svg";
export default createGlobalStyle`
  #root {
    position: relative;
    //min-height: 109vh;
    min-height: 100vh;
    overflow-x: hidden;
    &.overflow {
      height: 100vh;
      overflow: hidden;
    }
  }
  body {
    margin: 0;
    background-color: ${baseTheme.colors.bg};
    background-image: url(${BG});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 150px;
  }
  a {
    color: #fff;
    text-decoration: none;
    &:hover{
      color:#fff
    }
  }
  h1,h2,h3,h4,h5,h6,p {
    margin: 0;
  }
  .fadeIn {
    animation: fadeIn 0.5s ease-in-out forwards;
  }
  .fadeOut {
    animation: fadeOut 0.5s ease-in-out forwards;
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #root {
    .slideInLeft-appear,
    .slideInLeft-enter {
      transform: translateX(100%);
      opacity: 0;
    }
    .slideInLeft-enter-active, .slideInLeft-enter-done, .slideInLeft-appear-active, .slideInLeft-appear-done {
      transform: translateX(0%);
      opacity: 1;
      transition-duration: 0.4s;
    }
    .slideInLeft-exit {
      transform: translateX(0%);
      margin-left: 0;
      opacity: 1;
    }
    .slideInLeft-exit-active, .slideInLeft-exit-done {
      transform: translateX(100%);
      opacity: 0;
      transition-duration:  0.4s;
    }
    .slideInLeft-enter-active, .slideInLeft-appear-active, .slideInLeft-exit-active {
      will-change: transform;
    }

    .fade-appear,
    .fade-enter {
      transform: scale(1.1);
      opacity: 0;
    }
    .fade-enter-active, .fade-enter-done, .fade-appear-active, .fade-appear-done {
      transform: scale(1);
      opacity: 1;
      transition-duration: 0.4s;
      pointer-events: initial;
    }
    .fade-exit {
      transform: scale(1);
      opacity: 1;
      &.noTransitionEnd {
        transition: 0s;
      }
    }
    .fade-exit-active, .fade-exit-done {
      //transform: scale(0.9);
      transform: scale(1);
      opacity: 0;
      transition-duration:  0.4s;
      &.simpleOut {
        transform: scale(1);
      }
    }
    .fade-enter-active, .fade-appear-active, .fade-exit-active {
      will-change: transform;
    }
    
    .fade-appear,
    .fade-enter {
      transform: scale(1.1);
      opacity: 0;
    }
    .fade-enter-active, .fade-enter-done, .fade-appear-active, .fade-appear-done {
      transform: scale(1);
      opacity: 1;
      transition-duration: 0.4s;
      pointer-events: initial;
    }
    .fade-exit {
      transform: scale(1);
      opacity: 1;
      &.noTransitionEnd {
        transition: 0s;
      }
    }
    .fade-exit-active, .fade-exit-done {
      //transform: scale(0.9);
      transform: scale(1);
      opacity: 0;
      transition-duration:  0.4s;
      &.simpleOut {
        transform: scale(1);
      }
    }
    .fade-enter-active, .fade-appear-active, .fade-exit-active {
      will-change: transform;
    }

    .justFade-appear,
    .justFade-enter {
      opacity: 0;
    }
    .justFade-enter-active, .justFade-enter-done, .justFade-appear-active, .justFade-appear-done {
      opacity: 1;
      transition-duration: 0.4s;
      pointer-events: initial;
    }
    .justFade-exit {
      opacity: 1;
      &.noTransitionEnd {
        transition: 0s;
      }
    }
    .justFade-exit-active, .justFade-exit-done {
      //transform: translate(-50%,-50% scale(0.9);
      opacity: 0;
      transition-duration:  0.4s;
    }.justFade-appear,
     .justFade-enter {
       opacity: 0;
     }
    .justFade-enter-active, .justFade-enter-done, .justFade-appear-active, .justFade-appear-done {
      opacity: 1;
      transition-duration: 0.4s;
      pointer-events: initial;
    }
    .justFade-exit {
      opacity: 1;
      &.noTransitionEnd {
        transition: 0s;
      }
    }
    .justFade-exit-active, .justFade-exit-done {
      //transform: translate(-50%,-50% scale(0.9);
      opacity: 0;
      transition-duration:  0.4s;
    }
  }
  
`
