import React from "react";
import { StyledButton } from "./StyledButton";

const PrimaryButton = ({
  content,
  onClick,
  className,
  isDisabled,
  active,
  style = {},
}) => {
  return (
    <StyledButton
      style={style}
      onClick={onClick}
      className={className}
      disabled={isDisabled}
      open={active}
    >
      {content}
    </StyledButton>
  );
};

export default React.memo(PrimaryButton);
