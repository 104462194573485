import { Program, AnchorProvider } from '@project-serum/anchor';
import { IDL } from '../../dogeo-idl';
import {
  PublicKey,
  Keypair,
  Transaction,
  SystemProgram,
} from '@solana/web3.js';

import { TOKEN_PROGRAM_ID } from '@solana/spl-token';

import {
  generateCreateAndMintInstructions,
  generateMintInitInstructions,
} from './mint';
import {
  changeUpdateAuthorityInstruction,
  createMetadataInstruction,
} from './metadata';

import config from '../../config';

export function getAnchorProgram(connection, wallet) {
  return new Program(
    IDL,
    config.PROGRAM_ID,
    new AnchorProvider(connection, wallet, { commitment: 'confirmed' })
  );
}

export async function upgradeDoge(connection, wallet, newStats, accounts) {
  const { dogeMint, dogeToken, dogeMetadata, dtrkToken } = accounts;

  const dogeOProgram = getAnchorProgram(connection, wallet);
  const [dogeOPDA] = await PublicKey.findProgramAddress(
    [
      Buffer.from('dogeo'),
      config.INIT_AUTHORITY.toBuffer(),
      new PublicKey(dogeMint).toBuffer(),
    ],
    config.PROGRAM_ID
  );

  const { speed, endurance, agility } = newStats;

  const upgradeDogeTx = await dogeOProgram.methods
    .upgradeDoge({
      speed,
      endurance,
      agility,
    })
    .accounts({
      dogeStats: dogeOPDA,
      dtrkToken: new PublicKey(dtrkToken),
      dtrkMint: config.DTRK_MINT,
      dogeMint: new PublicKey(dogeMint),
      dogeToken: new PublicKey(dogeToken),
      dogeMetadata: new PublicKey(dogeMetadata),
      initAuthority: config.INIT_AUTHORITY,
      dogeHolder: wallet.publicKey,
      tokenProgram: TOKEN_PROGRAM_ID,
    })
    .signers([config.INIT_AUTHORITY])
    .transaction();

  const recentBlockhash = await connection.getLatestBlockhash();
  upgradeDogeTx.feePayer = wallet.publicKey;
  upgradeDogeTx.recentBlockhash = recentBlockhash.blockhash;

  const fullySignedTx = await wallet.signTransaction(upgradeDogeTx);

  const txHash = await connection.sendRawTransaction(
    fullySignedTx.serialize({ requireAllSignatures: true })
  );

  await connection.confirmTransaction(txHash, 'confirmed');
}

export async function initializeDogeO(program, initAuthority, stats) {
  const mintKeypair = Keypair.generate();
  const mintAuthKeypair = Keypair.fromSecretKey(
    Uint8Array.from(JSON.parse(process.env.REACT_APP_VERIFIED_CREATOR))
  );

  const generateFakeMintIx = await generateMintInitInstructions(
    mintKeypair,
    mintAuthKeypair,
    program.provider.connection,
    0
  );

  const { ix: createMetadataIx, metadata } = await createMetadataInstruction(
    mintAuthKeypair,
    mintKeypair.publicKey
  );

  const updateMetadataIx = await changeUpdateAuthorityInstruction(
    mintKeypair.publicKey,
    mintAuthKeypair,
    initAuthority.publicKey
  );

  const { instructions: mintIx, tokenAddress } =
    await generateCreateAndMintInstructions(
      initAuthority.publicKey,
      mintAuthKeypair.publicKey,
      mintKeypair.publicKey,
      program.provider.wallet.publicKey,
      1,
      0
    );

  const [dogeOPDA] = await PublicKey.findProgramAddress(
    [
      Buffer.from('dogeo'),
      initAuthority.publicKey.toBuffer(),
      mintKeypair.publicKey.toBuffer(),
    ],
    config.PROGRAM_ID
  );

  const preReqTx = new Transaction({
    feePayer: mintAuthKeypair.publicKey,
    recentBlockhash: (await program.provider.connection.getLatestBlockhash())
      .blockhash,
  }).add(...generateFakeMintIx, ...createMetadataIx, ...updateMetadataIx);

  const initTx = await program.methods
    .initStats({
      speed: stats.speed,
      agility: stats.agility,
      endurance: stats.endurance,
    })
    .accounts({
      dogeMint: mintKeypair.publicKey,
      dogeStats: dogeOPDA,
      initAuthority: initAuthority.publicKey,
      systemProgram: SystemProgram.programId,
      dogeMetadata: metadata,
    })
    .preInstructions([...mintIx])
    .signers([initAuthority, mintAuthKeypair])
    .transaction();

  const txs = await program.provider.sendAll(
    [
      {
        tx: preReqTx,
        signers: [mintKeypair, mintAuthKeypair],
      },
      {
        tx: initTx,
        signers: [initAuthority, mintAuthKeypair],
      },
    ],
    { commitment: 'confirmed' }
  );

  await program.provider.connection.confirmTransaction(txs[txs.length - 1]);

  return {
    txs,
    mint: mintKeypair.publicKey,
    tokenAddress,
    metadata,
    dogeOPDA,
  };
}
