import styled from "styled-components";
import {baseTheme} from "../../../../styles/baseTheme";

export const StyledMenu =  styled.div`
  position: relative;
  
   &.connect {
  //   margin-left: 76px;
     
     @media ${baseTheme.media.mobile} {
       margin: 0 49px 0 0;
     }
   }
  
  // @media (max-width: 1000px) {
  //   &.connect {
  //     margin-left: 20px;
  //   }
  // }
`
