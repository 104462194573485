import styled from "styled-components";
import {baseTheme} from "../../../styles/baseTheme";
import {NavLink} from "react-router-dom";

export const StyledHeader = styled.header`
  ${baseTheme.sizes.header};
  height: ${props => props.height ? props.height : 0 };
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
  position: relative;
  z-index: 2;
  & > a { display: contents; }
`
export const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  font-family: ${baseTheme.fonts.secondary};
  & > * {
    &:not(:first-child) { margin-left: 43px; }
    &.active { text-shadow: 0 0 2px #fff; }
  }

  @media (max-width: 1000px) {
    & > * {
      font-size: 15px;
      &:not(:first-child) { margin-left: 25px; }
    }
  }

  @media (max-width: 850px) {
    & > * {
      font-size: 14px;
      &:not(:first-child) { margin-left: 18px; }
    }
  }
  
  @media(max-width: 900px) {
    //&  > a { margin-left: 30px; }
  }
`
