import styled from "styled-components";
import {baseTheme} from "../../../styles/baseTheme";

export const StyledContainer = styled.div`
  ${baseTheme.sizes.container};
  margin: 0 auto;

  &.content-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &.section-analyzer {
    label {
      margin: auto;
    }
  }
`


