import styled from "styled-components";
import Button from "@mui/material/Button";
import {baseTheme} from "../../../../styles/baseTheme";

export const StyledCancelAll = styled(Button)`
  && {
    padding: 4px 12px;
    border: 1px solid #FF3743;
    color: #FF3743;
    box-sizing: border-box;
    border-radius: 12px 0px;
    transition: .3s;
    background-color: transparent;
    text-transform: initial;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.03em;

    &:hover {
      background-color: #FF3743;
      color: #fff;
    }
    @media(hover: none) {
      &:hover {
        color: #FF3743;
        background: transparent;
      }
      &:active {
        background-color: #FF3743;
        color: #fff;
      }
    }
  }`

export const StyledCharTable = styled.div`
  width: 63%;
  margin-right: 5%;
`

export const StyledUpgradeButton = styled(Button)`
  && {
    font-family: ${baseTheme.fonts.secondary};
    width: 32%;
    position: relative;
    background: ${baseTheme.colors.secondary};
    box-shadow: 0px 12px 24px rgba(40, 37, 191, 0.5);
    border-radius: 12px 0px;
    transition: .3s;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
      background: ${baseTheme.colors.hoverSecondary};
    }

    &:disabled {
      cursor: none;
      box-shadow: none;
      background: ${baseTheme.colors.borders};
    }

    @media (max-width: 387px) {
      width: 100px;
    }
    
    @media(hover: none) {
      &:hover {
        background: ${baseTheme.colors.secondary};
      }

      &:active {
        background: ${baseTheme.colors.hoverSecondary};
      }

      &:disabled {
        cursor: none;
        box-shadow: none;
        background: ${baseTheme.colors.borders};
      }
    }
  }
`

export const StyledUpgradeContent = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
`

export const StyledUpgradeText = styled.span`
  display: inline-block;
  margin-bottom: 8px;
`