import React, { useEffect, useState } from "react";
import Dog from "../../../uiComponents/Cards/Dog";
import { sortData, sortDogs, sortTracks } from "../../../utils/sortData";

import { StyledUserData } from "./StyledUserData";
import Track from "../../../uiComponents/Cards/Track";
import { countWinRate } from "../../../utils/countWinRate";
import getDogAttr from "../../../utils/getDogAttr";

const UserData = ({
  className,
  filters,
  user,
  setUser,
  showDogs,
  data,
  setChooseLobby,
}) => {
  const [content, setContent] = useState(data);

  const handleFilters = (item) => {
    const winRate = showDogs ? getDogAttr(item, "win_rate") : true;

    const winRated = winRate >= filters.winRate[0] && winRate <= filters.winRate[1];
    const filtered = showDogs
      ? filters.event_type.length
        ? filters.event_type.some(
            (filter) => getDogAttr(item, "class") === filter.filterKey
          )
        : true
      : true;
    const searched = filters.search
      ? item.name.toLowerCase().includes(filters.search)
      : true;
    return winRated && filtered && searched;
  };

  useEffect(() => {
    setContent(data);
  }, [data]);

  //sorting
  useEffect(() => {
    data
      ? showDogs
        ? setContent((currentContent) => sortDogs(currentContent, filters))
        : setContent((currentContent) => sortTracks(currentContent, filters))
      : null;
  }, [filters.sorting]);

  const selectDog = (dog) => setUser((user) => ({ ...user, selectDog: dog }));

  return (
    <StyledUserData className={className} isWallet={true}>
      {content.length &&
        content
          ?.filter((filt) => handleFilters(filt))
          ?.map((item, i) =>
            showDogs ? (
              <Dog
                dog={item}
                key={i}
                isWallet={true}
                onClick={selectDog}
                active={user.selectDog === item}
                secondaryState={user.selectDog}
              />
            ) : (
              <Track
                track={item}
                key={item._id}
                isWallet={true}
                onClick={setChooseLobby}
                secondaryState={!item.inRace}
              />
            )
          )}
    </StyledUserData>
  );
};

export default React.memo(UserData);
