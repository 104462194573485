import styled from "styled-components";
import StatsProgress from '../../../assets/img/stats-progress.png';

export const StyledDAEquipStats = styled.div`
  padding: 16px;
  font-size: 11px;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  //min-width: 217px;
  
  .wrap-stats {
    width: 100%;
    animation: fadeIn 0.5s forwards ease-in-out;
  }
`

export const StyledEquipmentUL = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  animation: fadeIn 0.5s forwards ease-in-out;
  
  li {
    padding: 7px 7px 9px 7px;
    display: flex;
    justify-content: space-between;
    &:nth-child(odd) {background: rgba(255, 255, 255, 0.05);}
    
    span {
      color: #747496;
      &:after {content: ':';}
    }
  }
`

export const StyledDATabs = styled.div`
  font-size: 14px;
  color: #9898B0;
`

export const StyledStatsProgress = styled.div`
  position: relative;
  height: 4px;

  &:before, &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

  }

  &:before {
    background: #44445F;
  }

  &:after {
    background: url(${StatsProgress}) no-repeat center;
    background-size: cover;
  }
`

export const StyledLineProgress = styled.div`
  background: #1CA97F;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform-origin: left;
  transition: transform 0.4s;
`

export const StyledStatsItem = styled.div`
  &:first-child {
    .stat-title {
      margin-top: 0;
    }
  }
  
  .stat-title {
    display: flex;
    justify-content: space-between;
    margin: 14px 0 13px;
  }
  
  .gray {
    color: #747496;
  }
`




