import * as React from "react";
import { StyledDACard } from "./StyledDogeAnalyzer";
import DogAnalyzerViewer from "./DogAnalyzerViewer/DogAnalyzerViewer";
import DogAnalyzerEquipStats from "./DogAnalyzerEquipStats/DogAnalyzerEquipStats";

const DogeAnalyzerCard = ({ dogData }) => {
  return (
    <StyledDACard className="fadeIn">
      <DogAnalyzerEquipStats dogData={dogData} />
    </StyledDACard>
  );
};

export default React.memo(DogeAnalyzerCard);
