import styled from "styled-components";
import {baseTheme} from "../../../styles/baseTheme";

export const StyledWinRateSlider = styled.div`
  h3 {
    position: relative;
    top: 5px;
  }

  .bar {
    position: relative;
    padding-left: 5px;

    .MuiSlider-rail {
      color: #7371D3;
      width: 100%;
      height: 2px;
    }

    .MuiSlider-colorPrimary {
      color: #fff;
      height: 1px;
    }

    .MuiSlider-thumbColorPrimary {
      width: 8px;
      height: 8px;
    }

    .MuiSlider-valueLabel {
      transform: translateY(50%) scale(1);
      background-color: transparent;

      .MuiSlider-valueLabelLabel {
        font-family: ${baseTheme.fonts.secondary};
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        color: #FFFFFF;

        &::after {
          content: "%";

        }
      }
    }
  }
  @media ${baseTheme.media.mobile} {
    .bar {
      .MuiSlider-thumbColorPrimary {
        width: 25px;
        height: 25px;
        color: #fff;
      }
      .MuiSlider-colorPrimary {
        color: ${baseTheme.colors.secondary};
        height: 1px;
      }
      .MuiSlider-valueLabel {
        margin-top: 21px;

        .MuiSlider-valueLabelLabel {
          font-size: 16px;
          line-height: 22px;
          font-weight: normal;
        }
      }
    }
  }
`