import styled from "styled-components";
import {baseTheme} from "../../../styles/baseTheme";

export const StyledTitleWallet = styled.div`
  h1 {
    font-family: ${baseTheme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 60px;
    text-transform: uppercase;
    color: #FFFFFF;
    span {
      color: ${baseTheme.colors.secondary};
    }
    margin-bottom: 10px;
    margin-top: 61px;
  }
  p {
    font-family: ${baseTheme.fonts.secondary};
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: #FFFFFF;
    margin-bottom: 36px;
  }
  & > *  {
    animation: fadeIn 0.5s forwards ease-in-out;
  }
  .analyzer-descr {
    max-width: 280px;
    text-align: center;
    margin: -3px auto 23px auto;
  }
`

export const StyledWalletNoUser = styled.div`
  text-align: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  h1 {
    font-family: ${baseTheme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 60px;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 301px;
    margin: 0 auto 8px ;
    span {
      color: ${baseTheme.colors.secondary};
    }
  }
  p {
    font-family: ${baseTheme.fonts.secondary};
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: #FFFFFF;
    width: 249px;
    margin: 0 auto 23px;
  }
  button {
    margin: 0 auto;
    display: inline-block;
  }
  & > *  {
    animation: fadeIn 0.5s forwards ease-in-out;
  }
`
