import React from 'react';
import {StyledInput} from "./StyledInput";

const Input = ({placeholder, value, onChange, maxLength, className}) => {

    const handleInput = (e) => {
        e.preventDefault();
        onChange(e.target.value);
    }

    return (
        <StyledInput className={className} placeholder={placeholder} value={value} onChange={(e) => handleInput(e)} maxLength={maxLength ? maxLength : 20} />
    );
};

export default React.memo(Input);