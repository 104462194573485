import styled from "styled-components";
import {baseTheme} from "../../../styles/baseTheme";

export const StyledInput = styled.input`
    width: 100%;
    height: 36px;
    font-family: ${baseTheme.fonts.secondary};
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    border-radius: 12px 0;
    padding: 11px 19px;
    background-color: transparent;
    border: 1px solid #3C3C54;
    color: #fff;
    position: relative;
    box-sizing: border-box;
  
    &::placeholder {
      color: #3C3C54;
    }

    &:focus, &:active {
      outline: none;
      border-color: ${baseTheme.colors.secondary};
      &::after {
        content: ${(value, marker) => value ? marker : "none"};
      }
    }
  
`