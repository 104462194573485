import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import APIProvider from "./Providers/APIProvider";
import { WalletKitProvider } from "@gokiprotocol/walletkit";
import config from "./config";
import "./index.css";

fetch(`${config.SERVER_ADDRESS}/get_token`)
  .then((res) => res.json())
  .then(async (token) => {
    ReactDOM.render(
      <WalletKitProvider
        defaultNetwork="mainnet-beta"
        networkConfigs={{
          "mainnet-beta": {
            endpoint: `${config.SERVER_ADDRESS}/api/wallet2`,
            endpointWs: `${config.SERVER_WS}/?wallet=${token.token}`,
            fetch: async (url, req) => {
              if (url.includes('wallet2')) {
                req.headers['Authorization'] = `Bearer ${token.token}`;
              }
              return await fetch(url, req)
            },
            confirmTransactionInitialTimeout: 90 * 1000,
            commitment: "confirmed",
          },
        }}
        app={{
          name: "DogeO Training",
          icon: (
            <img
              src="https://analyzer.dogetrack.io/favicon.png"
              alt="DogeO Training"
              width="60px"
            />
          ),
        }}
      >
        <Router>
          <APIProvider>
            <Switch>
              <App/>
            </Switch>
          </APIProvider>
        </Router>
      </WalletKitProvider>,
      document.getElementById("root")
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
