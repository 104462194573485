import React from 'react';
import Slider from "@mui/material/Slider";

import {StyledWinRateSlider} from "./StyledWinRateSlider";

const WinRateSlider = ({filters, setFilter, className, noHeader}) => {
    return (
        <StyledWinRateSlider className={className}>
            {noHeader ? null : <h3>Winrate Filter</h3>}
            <div className="bar">
                <Slider
                    value={filters.winRate}
                    onChange={(e) => setFilter("winRate", e.target.value)}
                    valueLabelDisplay="on"
                />
            </div>
        </StyledWinRateSlider>
    );
};

export default React.memo(WinRateSlider);