import React from 'react';
import {StyledSpecialSelector} from "./StyledSpecialSelector";

const SpecialSelector = ({showFirst, showItems, content, className}) => {
    return (
        <StyledSpecialSelector className={className} showFirst={showFirst}>
            <button className={"tab__select" + (showFirst ? " active" : "")} onClick={()=>showItems(0)}>{content[0]}</button>
            <button className={"tab__select" + (showFirst ? "" : " active")} onClick={()=>showItems(1)}>{content[1]}</button>
        </StyledSpecialSelector>
    );
};

export default React.memo(SpecialSelector);
