import React from "react";
import {StyledSpinner} from "./styledSpinner";

const Spinner = () => {
    return (
        <StyledSpinner>
            <div className="spinner" />
        </StyledSpinner>
    )

}

export default Spinner;
