import React from "react";
import {StyledFooter} from "./StyledFooter";
import {StyledContainer} from "../Container/StyledContainer";

const Footer = () => {
  return <StyledFooter>
    <StyledContainer>
      <div className="footer-links">
        <a href="https://dogetrack.io/page/privacy-policy">Privacy Policy</a>
        <a href="https://dogetrack.io/page/terms-of-service">Terms Of Service</a>
        <a href="https://dogetrack.io/system-requirements">System Requirements</a>
      </div>
      <div className="doge-track">All Rights Reserved. Doge Track.</div>
    </StyledContainer>
  </StyledFooter>
}

export default React.memo(Footer);
