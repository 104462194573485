export const baseTheme = {
  colors: {
    primary: "#090922",
    secondary: "#2825BF",
    hoverSecondary: "#302DE0",
    headers: "#1A1A30",
    hoverItem: "#6B68DD",
    bg: "#070713",
    alert: "#FF3743",
    alertHover: "#fa505a",
    borders: "#3C3C54",
  },
  fonts: {
    primary: "'Teko', sans-serif",
    secondary: "'Noto Sans', sans-serif",
    japan: "'Gang of Three', sans-serif"
  },
  // in px
  sizes: {
    header: { height: 64 },
    container: { maxWidth: 1110, paddingLeft: 20, paddingRight: 20 },
    footer: { height: 68 },
    modal: { width: 540 },
  },
  //
  mixins: {
    classButton: (fontSize) => ({
      fontFamily: "'Teko', sans-serif",
      width: "57px",
      height: "21px",
      border: "1px solid #2825BF",
      boxSizing: "border-box",
      borderRadius: "8px 0",
      verticalAlign: "middle",
      padding: "1px 6px 0 9px",
      color: "#fff",
      fontSize: fontSize,
      lineHeight: "initial",
    }),
  },
  // in ms
  durations: {
    ms300: 0.3 + "s",
    ms400: 0.4 + "s",
  },
  // z-index
  order: {
    header: 50,
    modal: 100,
  },
  // keyframes
  keyframes: {
    fadeIn: (delay = 0, duration = 0.5) => animation("fadeIn", duration, delay),
    fadeOut: (delay = 0, duration = 0.5) => animation("fadeOut", duration, delay),
    swipeInLeft: (delay = 0, duration = 0.4) => animation("swipeInLeft", duration, delay),
    swipeOutLeft: (delay = 0, duration = 0.4) =>
      animation("swipeOutLeft", duration, delay),
  },
  media: {
    mobile: "(max-width: 768px)",
  },
};

const animation = (name, duration, delay) => ({
  "animation-name": `${name}`,
  "animation-duration": `${duration}s`,
  "animation-delay": `${delay}s`,
  "animation-iteration-count": "1",
  "animation-timing-function": "ease-in-out",
  "animation-fill-mode": "forwards",
});
