import React from 'react';
import {StyledCreateLobbyItem} from "./StyledCreateLobbyItem";

const CreateLobbyItem = ({title,children,error, value}) => {
    return (
        <StyledCreateLobbyItem value={value}>
            <h3 className="create__lobby-title">{title}:</h3>
            <div className={"create__lobby-content" + (error ? " error" : "")}>{children}</div>
        </StyledCreateLobbyItem>
    );
};

export default React.memo(CreateLobbyItem);