import React, {useEffect, useState} from 'react';

import Table from "@mui/material/Table";
import {sorting, trackSorting} from "../../../InfoData/walletFilters";
import {filtersLIB} from "../../../InfoData/tableFilters";
import Input from "../../../uiComponents/General/Input/Input";
import DropDown from "../../../uiComponents/General/DropDown/DropDown";
import FilterSelect from "../../../uiComponents/FilterSelect/FilterSelect";
import MobileFilters from "../../../uiComponents/Mobile/MobileFilters/MobileFilters";

import {StyledFilters} from "./StyledFilters";

import {ReactComponent as FilterSVG} from "../../../assets/img/icons/filter.svg";
import {ReactComponent as Search} from "../../../assets/img/icons/search.svg";

const Filters = ({showFilters, setShowFilters, className, filters, setFilter, isMobile, setFilters, isTrack}) => {
    const [inputValue, setInputValue] = useState("");

    const handleInput = (value) => {
        setInputValue(value);
        setFilter('search', value.toLowerCase());
    }

    const checkActiveVariantFilter = (item) => filters.event_type.some(filter => filter === item) ? " active" : "";

    const resetFilter = (isAll, isClass) => {
        if(isMobile) {
            if(isAll) setFilters({winRate: [0, 100], event_type: [], sorting: -1, search: "",})
            else if (isClass) setFilter("event_type", []);
            else setFilter("winRate", [0, 100]);
        }
        else setFilter("event_type", []);
    }

    const handleFilters = (item) => {

        const removedArr = (pos) => {
            const {event_type} = filters;
            event_type.splice(pos, 1);
            return event_type;
        };

        const removeOrPush = filters.event_type.findIndex(clas => clas === item);

        removeOrPush === -1 ?
            setFilter('event_type', [...filters.event_type, item])
            :
            setFilter('event_type', removedArr(removeOrPush))

        if(isMobile) setShowFilters(true);

    }

    const handleSorting = (i) => setFilter('sorting', i)

    const setInitialOptions = (initialOptions) => setFilters(initialOptions);

    const filterSelectData = ({
        filters,
        setFilter,
        resetFilter,
        showFilters,
        setShowFilters,
        setInitialOptions,
        filterOptions: filters,
        checkActiveVariantFilter,
        handleFilterVariants: handleFilters,
        activeFilterItems: filtersLIB[3],
    });

    const Sorting = () => <DropDown activeVariant={filters?.sorting} setActiveVariant={handleSorting} variants={isTrack ? trackSorting : sorting }/>;

    return (

        <>
            <StyledFilters className={className}>
                <div className="top-bar">
                    <div className="left">
                        <label>
                            <Input placeholder={"Search by name"} value={inputValue} onChange={handleInput}/>
                            <Search/>
                        </label>
                        {isMobile || isTrack? null :
                            <button onClick={() => setShowFilters(!showFilters)} className={"filter" + ((showFilters || filters.event_type.length || !(filters.winRate[0] === 0 && filters.winRate[1] === 100)) ? " active" : "")}>
                                FILTER<FilterSVG/>
                            </button>
                        }
                    </div>

                    {isMobile ?
                        <MobileFilters filterSelectData={filterSelectData} sorting={<Sorting/>} />
                        : <Sorting/>
                    }
                </div>
            </StyledFilters>

            {isMobile ? null :
                <Table>
                    <FilterSelect filterSelectData={filterSelectData} isWallet={true}/>
                </Table>}
        </>
    );
};

export default React.memo(Filters);
