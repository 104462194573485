import styled from "styled-components";

import {baseTheme} from "../../../styles/baseTheme";

export const StyledDropDown = styled.div`
    position: relative;

    .dropdown {
      background: #11111F;
      border: 1px solid #3C3C54;
      box-sizing: border-box;
      border-radius: 12px 0;
      width: 162px;
      height: 36px;
      padding: 0 17px 1px;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 15px;
      color: #63637C;
      font-family: ${baseTheme.fonts.secondary};
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      svg {
        transform: rotate(${props => props.title === "Select track type" ? "180deg" : "0deg"});
      }
          
      &:focus, &:active {
            border-color: ${baseTheme.colors.secondary};
      }
    }

    .variants {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
      ${props => props.title === "Select track type" ? "bottom": "top" }: 42px;
      width: 100%;
      transition: .3s;
      padding-left: 0;
      margin: 0;

      li {
        background: #11111F;
        border: 1px solid #3C3C54;
        box-sizing: border-box;
        list-style: none;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #FFFFFF;
        font-family: ${baseTheme.fonts.secondary};
        padding: 11px 12px;
        border-top: none;
        cursor: pointer;
        transition: .3s;

        &:first-child {
          border-top: 1px solid #3C3C54;
        }

        &:hover, &.active {
          background-color: #202038;
        }
      }
    }
    
    &.active {
      .dropdown {
        svg {
        transform: rotate(${props => props.title === "Select track type" ? "0deg" : "180deg"});
          pointer-events: none;
        }
      }

      .variants {
        opacity: 1;
        pointer-events: initial;
      }
    }
`