export const headers = [
    {name: "Event name", key: "name"},
    {name: "Location", key: "location"},
    {name: "Track Type", key: "track_type"},
    {name: "Class", key: "event_type"},
    {name: "Status", key: "stat"},
    {name: "Entry Fee", key: "amount_of_coin"},
    {name: "Laps", key: "laps"},
    {name: "Players", key: "players"}
]

export const filtersLIB = [
    [//Event Name
        {name: "Elon's Doge Track", filterKey: "Elon"},
        {name: "Marvel Doge Track", filterKey: "Marvel"},
        {name: "Mark's Doge Track", filterKey: "Mark"},
        {name: "Zuckerberg's Doge Track", filterKey: "Zu"},
        {name: "Santa's Doge Track", filterKey: "Santa"},
        {name: "Lego's Doge Track", filterKey: "Lego"},
    ],
    [//Location
        {name: "Olympic Stadium", filterKey: "Ol"},
        {name: "City Sprint", filterKey: "Ci"},
    ],
    [//TrackType
        {name: "Dirt", filterKey: "Dir"}
    ],
    [//EventType
        {name: "Beginner", filterKey: 0},
        {name: "Class I", filterKey: 1},
        {name: "Class II", filterKey: 2},
        {name: "Class III", filterKey: 3},
        {name: "Class IV", filterKey: 4},
        {name: "Class V", filterKey: 5},
    ],
    [//Status
        {name: "Open", filterKey: "1"},
        {name: "Preparing For Race", filterKey: "0"},
        {name: "In Process", filterKey: "2"},
    ],
    [//Amount of Coin
        {name: '100 coins', filterKey: '100'},
        {name: '250 coins', filterKey: '250'},
        {name: '500 coins', filterKey: '500'},
    ],
    [//Laps
        {name: '1 Lap', filterKey: '1'},
        {name: '2 Laps', filterKey: '2'},
        {name: '3 Laps', filterKey: '3'},
    ],
    [//Players
        {name: '1 Player', filterKey: 1},
        {name: '2 Players', filterKey: 2},
        {name: '3 Players', filterKey: 3},
        {name: '4 Players', filterKey: 4},
        {name: '5 Players', filterKey: 5},
    ],

]
