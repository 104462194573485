import React from 'react';
import {StyledError} from "./StyledError";
import CSSTransition from "react-transition-group/CSSTransition";
import {ReactComponent as Exit} from "../../../assets/img/icons/exit.svg";
import PrimaryButton from "../../General/Buttons/Primary/PrimaryButton";

const Error = ({error, setError, text, classNames}) => {

    const close = () => setError(false);

    return (
        <CSSTransition in={!!error && error === "error"} classNames="fade" timeout={400} unmountOnExit >
            <StyledError>
                <div className="overlay" onClick={()=>close()}/>
                <div className={"error " + classNames}>
                    <Exit onClick={()=>close()} className="error-exit"/>
                    <h2 className="error-title">{text || "Lobby wasn't created"}</h2>
                    <PrimaryButton content="Close" className="close" onClick={()=>close()}/>
                </div>
            </StyledError>
        </CSSTransition>
    );
};

export default React.memo(Error);
