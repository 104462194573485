import styled from "styled-components";
import {baseTheme} from "../../../styles/baseTheme";

export const StyledCounter = styled.div`
  font-family: ${baseTheme.fonts.secondary};
  justify-content: space-between;
  border: 1px solid #3C3C54;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  border-radius: 32px;
  font-style: normal;
  min-width: 88px;
  line-height: 15px;
  font-weight: 500;
  font-size: 11px;
  color: #9494C1;
  padding: 5px;

  .counter__button {
    width: 22px;
    border: none;
    height: 22px;
    display: flex;
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    min-width: initial;
    font-style: normal;
    align-items: center;
    border-radius: 22px;
    justify-content: center;
    background-color: #222236;
    cursor: pointer;

    &:nth-child(1) {
      margin-right: 10px;
    }

    &:nth-child(2) {
      margin-left: 10px;
    }
    
    &:active {
      background-color: ${baseTheme.colors.secondary};
    }
    &:hover {
      background-color: #2d2d48;
    }
  }
`