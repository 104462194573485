import React from "react";

import PrimaryButton from "../../../uiComponents/General/Buttons/Primary/PrimaryButton";

import { StyledTitleWallet, StyledWalletNoUser } from "./StyledTitleWallet";

import { useWalletKit } from "@gokiprotocol/walletkit";

const TitleWallet = ({ user }) => {
  const { connect } = useWalletKit();

  return user ? (
    <StyledTitleWallet>
      <h1 className="title">
        YOUR <span>DOGES</span>
      </h1>
      <p>Here you can view all your doges and their statistics.</p>
    </StyledTitleWallet>
  ) : (
    <StyledWalletNoUser>
      <h1 className="title">Wallet isn’t connected</h1>
      <p>
        To have access to your purchases - connect your wallet. After connecting, all your
        doges/tracks will be displayed here.
      </p>
      <PrimaryButton content="Connect Wallet" onClick={connect} />
    </StyledWalletNoUser>
  );
};

export default React.memo(TitleWallet);
