import styled from "styled-components";
import DogeViewer from "../../../assets/img/dogs/dogeViewer.jpg";

export const StyledDAViewer = styled.div`
  box-sizing: border-box;
  width: 278px;
  min-width: 278px;
  height: 285px;
  //background: indianred;
  position: relative;
  // background: url(${DogeViewer}) no-repeat;
  // background-size: cover;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

export const StyledDAViewerName = styled.div`
  background: linear-gradient(92.53deg, rgba(41, 212, 161, 0.2) 0, rgba(41, 212, 161, 0) 100%), #202034;
  position: absolute;
  left: 14px;
  right: 14px;
  bottom: 10px;
  padding: 7px 7px 7px 20px;
  color: white;
  
  &:before {
    content: '';
    position: absolute;
    width: 3px;
    left: 4px;
    top: 4px;
    bottom: 4px;
    background: #29D4A1;
  }
  
  .name {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 2px;
    text-transform: uppercase;
  }
  
  .under-name {
    font-size: 12px;
    opacity: 0.8;
    margin-bottom: 1px;

    .dog-id {
      opacity: 0.5;
      margin-right: 3px;
    }
  }
`
