import { clusterApiUrl } from '@solana/web3.js';
import trackDevMintList from './tracks-dev-mint-list.json';
import trackMintList from './tracks-mint-list.json';

import dogeDevMintList from './doge-dev-mint-list.json';
import dogeMintList from './doge-mint-list.json';

import { PublicKey } from '@solana/web3.js';

export default {
  GAME_ADDRESS: 'https://play.dogetrack.io',
  SERVER_ADDRESS: 'https://play.dogetrack.io',
  SERVER_WS: 'wss://play.dogetrack.io',
  RPC_URL:
    process.env.REACT_APP_ENV === 'development'
      ? process.env.REACT_APP_DEVNET_RPC_URL || clusterApiUrl('devnet')
      : process.env.REACT_APP_RPC_URL || clusterApiUrl('mainnet-beta'),
  RPC_URL_WS: process.env.REACT_APP_RPC_URL_WS,
  TRACK_MINT_LIST:
    process.env.REACT_APP_ENV === 'development'
      ? trackDevMintList
      : trackMintList,
  DOGE_MINT_LIST:
    process.env.REACT_APP_ENV === 'development'
      ? dogeDevMintList
      : dogeMintList,
  PROGRAM_ID:
    process.env.REACT_APP_ENV === 'development'
      ? new PublicKey('2FJPtjHmYZBAeFLudMHFgQAq5vsEMW6uXhc8TqtVZvMS')
      : new PublicKey('BQmZuoj4q3gU7Qzqi3siJ4R48Tj9uua3AMDhqTDNDdFu'),
  INIT_AUTHORITY:
    process.env.REACT_APP_ENV === 'development'
      ? new PublicKey('3zKbAa4UEsHKx2Q6XQTZcmgv8NxmZtmns26AxZbcVGGc')
      : new PublicKey('4DRR9ZY5vBZboaKmLFpuUsmFLCHcmZHkpDZaobyPnB5f'),
  DTRK_MINT: new PublicKey('DTRK1XRNaL6CxfFWwVLZMxyhiCZnwdP32CgzVDXWy5Td'),
  TIME_LOBBY_TO_START: 10, // just UI:
};
