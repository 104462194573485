import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import Logo from "../../../assets/img/logo.svg";
import {menu} from "../../../InfoData/menu";
import useAPI from "../../../Hooks/useAPI";

import MenuItem from "../../Mobile/MobileMenu/MenuItem/MenuItem";
import MobileMenu from "../../Mobile/MobileMenu/MobileMenu";

import Menu from "./Menu/Menu";

import {StyledContainer} from "../Container/StyledContainer";
import {StyledHeader, StyledNav} from "./StyledHeader";
import {useWallet, useWalletKit} from "@gokiprotocol/walletkit";
import WalletList from "./Menu/WalletList/WalletList";
import PrimaryButton from "../Buttons/Primary/PrimaryButton";


const Header = () => {
    const {isMobile} = useAPI();
    const { connect } = useWalletKit();
    const { wallet, disconnect } = useWallet();
    const [isOpenList, setIsOpenList] = useState(false);

    const key = wallet?.publicKey?.toString();

    const copy = () => {
        navigator.clipboard.writeText(key);
    }

    const walletKey = key ? key.substring(0,4) + "..." + key.substring(key.length-4) : "";

    return (
        <StyledContainer className="header-container">
            <StyledHeader height={120}>
                <NavLink to="/"><img src={Logo} alt="logo"/></NavLink>
                {isMobile
                    ? (<>
                        <MobileMenu header="menu" isFilters={false}>
                        {menu.map((item, i) => {
                            if (item.regularLink) {
                                return <a href={item.path} key={i}>{item.title}</a>
                            }

                            return (
                                <MenuItem
                                    activeClassName='active'
                                    exact={item.exact}
                                    key={i}
                                    to={item.path}
                                    i={i}
                                    title={item.title}
                                />
                            )
                        })}
                        {/*{menu.map((item, i) =>*/}
                        {/*    <MenuItem activeClassName="active" exact={item.exact} key={item.title} to={item.path} i={i} title={item.title}/>*/}
                        {/*)}*/}
                    </MobileMenu>
                        <Menu className="connect" onClickAway={() => setIsOpenList(false)}>
                            <WalletList open={isOpenList} setIsOpen={setIsOpenList} disconnect={disconnect} copy={copy}/>
                            <PrimaryButton
                                className="connect"
                                active={wallet?.publicKey}
                                content={wallet?.connected ? walletKey : "Connect"}
                                onClick={wallet?.connected ? () => setIsOpenList(!isOpenList) : connect}
                            />
                        </Menu>
                      </>
                    )
                    : (<StyledNav>
                        {menu.map((item, i) => {
                            if (item.regularLink) {
                                return <a href={item.path} key={i}>{item.title}</a>
                            }

                            else {
                                return (
                                    <NavLink
                                        activeClassName={'active'}
                                        exact={item.exact}
                                        key={i}
                                        to={item.path}
                                    >
                                        {item.title}
                                    </NavLink>
                                )}
                            }
                        )}
                        <Menu className="connect" onClickAway={() => setIsOpenList(false)}>
                            <WalletList open={isOpenList} setIsOpen={setIsOpenList} disconnect={disconnect} copy={copy}/>
                            <PrimaryButton
                                className="connect"
                                active={wallet?.publicKey}
                                content={wallet?.connected ? walletKey : "Connect"}
                                onClick={wallet?.connected ? () => setIsOpenList(!isOpenList) : connect}
                            />
                        </Menu>
                      </StyledNav>
                    )
                }
            </StyledHeader>
        </StyledContainer>
    )
}


export default React.memo(Header);
