import React, {useState} from 'react';
import {StyledDropDown} from "./StyledDropDown";
import {sorting} from "../../../InfoData/walletFilters";
import {ReactComponent as ArrDown} from "../../../assets/img/icons/ArrDown.svg";
import {ClickAwayListener} from "@mui/material";

const DropDown = ({title, variants, activeVariant, setActiveVariant}) => {

    const [isOpenedDropDown, setIsOpenedDropDown] = useState(false);

    const handleSorting = (i) => {
        setActiveVariant(i);
        setIsOpenedDropDown(false);
    }

    return (
        <ClickAwayListener onClickAway={()=>setIsOpenedDropDown(false)}>
            <StyledDropDown className={"dropdown__container" + (isOpenedDropDown ? " active" : "")} title={title}>
                    <button className="dropdown" onClick={() => setIsOpenedDropDown(!isOpenedDropDown)} type="button">
                        { typeof activeVariant === "number" ?
                            activeVariant !== -1 ? variants[activeVariant] : title ? title : "Sort by"
                            : activeVariant ? activeVariant : title ? title : "Sort by"
                        }
                        <ArrDown/>
                    </button>
                    <ul className="variants">
                        {variants.map((item, i) => (
                            <li key={item} onClick={() => handleSorting(i)} className={ typeof activeVariant === "number" ? i === activeVariant ? " active" : "" : item === activeVariant ? " active" : ""}>{item}</li>
                        ))}
                    </ul>
            </StyledDropDown>
        </ClickAwayListener>
    );
};

export default React.memo(DropDown);