export const menu = [
  {
    exact: true,
    path: "/",
    title: "My Doges",
  },
  // {
  //   path: 'https://play.dogetrack.io/leaderboard',
  //   title: 'Leaderboard',
  //   regularLink: true,
  // },
  {
    path: 'https://famousfoxes.com/tokenmarket/DTRK1XRNaL6CxfFWwVLZMxyhiCZnwdP32CgzVDXWy5Td',
    title: '$DTRK',
    regularLink: true,
  },
  {
    path: 'https://doge-track.gitbook.io/doge-track-whitepaper-v0.1/breakdown/doges',
    title: 'Learn More',
    regularLink: true,
  }
];
