import styled from "styled-components";
import {baseTheme} from "../../../../styles/baseTheme";
import Corner from "../../../../assets/img/corner.svg";

export const StyledEquipStatsItem = styled.div`
  img {
    user-select: none;
    height: 100%;
  }
  .dog__nft-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 13px;
  }

  position: relative;

  span:not(.dog-id) {
    padding: 6px 14px 3px;
    background-color: #363644;
    border: 4px solid #2D2D3A;
    border-bottom-width: 26px;
    margin-left: auto;
    position: absolute;
    right: 26px;
    top: 0;
    min-width: 81px;
    z-index: 2;
    box-sizing: border-box;
    background-position-x: 75%;
    background-position-y: 44%;
    background-size: 21%;

    &::after {
      content: "In your wallet";
      position: absolute;
      top: 114%;
      left: 0;
      right: 0;
      font-family: ${baseTheme.fonts.secondary};
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 15px;
      text-align: center;
      color: #9898B0;
    }

  }
  //@media ${baseTheme.media.mobile} { 
  @media (max-width: 780px) {
    img {
      height: initial;
      width: 100%;
      font-size: 0;
    }
  }
`

export const StyledName = styled.div`
  // font-family: ${baseTheme.fonts.japan};
  position: absolute;
  bottom: 16px;
  left: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 41px;
  margin-left: -2px;
  color: #FFFFFF;
`

export const StyledDogNumber = styled.div`
  //font-family: ${baseTheme.fonts.japan};
  position: absolute;
  left: 16px;
  bottom: 64px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #9898B0;
`
