import React from "react";
import Training from "../Sections/DogeTrain/Training/Training";
import { StyledContainer } from "../uiComponents/General/Container/StyledContainer";
import BigBg from "../assets/img/bg/bg.png";

const DogeTrain = ({ setSpinner }) => {
  return (
    <StyledContainer>
      {/*<Title/>*/}

      <Training setSpinner={setSpinner} />

      <img src={BigBg} alt="Japanese theme" className="japanese__bg" />
    </StyledContainer>
  );
};

export default React.memo(DogeTrain);
