const getDogAttr = (dogData, attributeName) => {
  switch (attributeName.toLowerCase()) {
    case 'id': return dogData.solana_id || dogData.name.split('#')[1].trim();
    case 'name': return dogData.dogName || dogData.name.split('#')[0].trim();
    case "inrace": return dogData.inRace;
    case 'class': return countDogClass(dogData);
    case 'class_index': return getDogClassValue(dogData, "index");
    case 'class_name': return getDogClassValue(dogData, "text");
    case 'winninghistory':
    case "win_rate": return Math.trunc(countWinRate(dogData));
    case "solana_id": return dogData.solana_id
  }

  if (dogData?.attributes)
    for (const attribute of dogData.attributes) {
      if (attributeName.toLowerCase() === attribute.trait_type.toLowerCase()) {
        return attribute.value;
      }
    }
};

export const countLineage = (item) => {
  return (item.endurance + item.speed + item.agility) / 3;
}

export const getDogClassValue = (classValue, format) => {
  classValue = countDogClass(classValue);
  if(classValue >= 0 && classValue <= 20){
    return format === "text" ? "Class I" : 1;
  }
  else if(classValue > 20 && classValue <= 40){
    return format === "text" ? "Class II" : 2;
  }
  else if(classValue > 40 && classValue <= 60){
    return format === "text" ? "Class III" : 3;
  }
  else if(classValue > 60 && classValue <= 80){
    return format === "text" ? "Class IV" : 4;
  }
  else {
    return format === "text" ? "Class V" : 5;
  }
}

export const countDogClass = (item) => {
  return countLineage(item) || 0;
  // return (countLineage(item) || 0) * 0.5 + (getDogAttr(item, 'winningHistory') || 0) * 0.5;
  // with winning history ->
  // return (countLineage(item) || 0) * 0.5 + (item.winningHistory || 0) * 0.5;
}

export const countWinRate = (item) => {
  return item.count_of_win ? (item.count_of_win / item.count_of_races * 100) : 0;
}

export default getDogAttr;
