import {useEffect, useMemo, useState} from "react";
import * as React from "react";

import {APIContext} from "../Context/APIContext";
import {baseTheme} from "../styles/baseTheme";

const APIProvider = (props) => {
    const [user, setUser] = useState([]);
    const [lobbies, setLobbies] = useState([]);

    const mediaQuery = window.matchMedia(baseTheme.media.mobile);
    const [isMobile, setIsMobile] = useState(mediaQuery.matches);

    const handleTabletChange = (e) => setIsMobile(!!e.matches);

    const setLobby = (callBack, lobbyID) => {
        setLobbies((curLobbies) => curLobbies.map(item => item._id === lobbyID ? callBack(item) : item));
    }

    const contextValue = useMemo(
        () => ({
            user,
            setUser,
            lobbies,
            setLobbies,
            setLobby,
            isMobile,
        }),
        [user, setUser, lobbies, setLobbies, setLobby, isMobile]
    );

    useEffect(()=> {
        mediaQuery.addListener(handleTabletChange);
        return () => {
            mediaQuery.removeListener(handleTabletChange)
        }
    },[isMobile])

    return (
        <APIContext.Provider value={contextValue}>
            {props.children}
        </APIContext.Provider>
    );
}

export default APIProvider;
