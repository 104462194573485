import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';

import config from '../../../config';
import { useWallet } from '@gokiprotocol/walletkit';
import { useConnectedWallet } from '@saberhq/use-solana';
import { getDtrkBalance } from '../../../InfoData/fetchUserNFT';

import { StyledDAEquipStats } from '../../DogeAnalyzer/DogAnalyzerEquipStats/StyledDAEquipStats';

import { calculateCost } from '../../../utils/calculateDtrk';
import {
  setAnchorProvider,
  setAnchorProgram,
  upgradeDoge,
} from '../../../utils/web3/dogeo';

import {
  StyledLoading,
  StyledTraining,
  StyledUpdateLoading,
} from './StyledTraining';

import Improvemets from './Improvements/Improvemets';
import Stats from '../../../uiComponents/Cards/Stats/Stats';
import Nft from './Nft/Nft';
import { CSSTransition } from 'react-transition-group';
import UpdateDog from '../../../assets/loaders/dogeUpdate.mp4';
import TrainingLoad from '../../../assets/loaders/trainingLoad.gif';
import './PageStyles.css';
import Error from '../../../uiComponents/Modals/Error/Error';
import { StyledEquipStatsItem } from './StyledEquipStatsItem/StyledEquipStatsItem';
import EquipStatsItem from './StyledEquipStatsItem/EquipStatsItem';
import { StyledCancelAll } from './Improvements/StyledImprovemets';

const Training = ({ setSpinner }) => {
  const { dogeId } = useParams();
  const [showError, setShowError] = useState('false');
  const [userDoge, setUserDoge] = useState(null);
  const [newStats, setNewStats] = useState(null);
  const [dtrkFees, setDtrkFees] = useState(0);
  const [loadings, setLoadings] = useState({
    loadedImg: false,
    gifAnimationEnd: false,
    updateLoading: false,
  });

  const { connection } = useWallet();
  const wallet = useConnectedWallet();

  const history = useHistory();

  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000);
    const oneDayInSeconds = 60 * 60 * 24;
    const res = JSON.parse(localStorage.getItem('userDoges') || '{}');

    if (!res.userDoges || res.cacheUpdatedAt < currentTime - oneDayInSeconds) {
      history.push('/');
    } else {
      const currentDoge = res.userDoges.filter((doge) =>
        doge.name.endsWith(`#${dogeId}`)
      )[0];

      if (!currentDoge) {
        history.push('/');
        return;
      }

      setUserDoge(currentDoge);

      setNewStats({
        endurance: currentDoge.endurance,
        agility: currentDoge.agility,
        speed: currentDoge.speed,
      });
    }
  }, []);

  useEffect(() => {
    async function fetchDTRKBalance() {
      const { balance: tokenBalance, address: tokenAddress } =
        await getDtrkBalance(wallet.publicKey, connection);

      const {
        data: {
          body: { endurance, speed, agility, dogName },
        },
      } = await axios.get(`${config.SERVER_ADDRESS}/api/metadata/${dogeId}`);

      setUserDoge((userDoge) => ({
        ...userDoge,
        endurance,
        speed,
        agility,
        tokenBalance,
        tokenAddress,
        dogName,
      }));
    }

    wallet?.publicKey && fetchDTRKBalance();
  }, [wallet?.publicKey]);

  useEffect(() => {
    if (newStats && userDoge) {
      const { endurance, agility, speed } = userDoge;
      const fees = calculateCost({ endurance, agility, speed }, newStats);
      setDtrkFees(fees);
    }
  }, [newStats]);

  const dogeStats = React.useMemo(() => {
    if (!userDoge) return null;
    const { endurance, agility, speed } = userDoge;
    return {
      endurance,
      agility,
      speed,
    };
  }, [userDoge]);

  const isStatChanged = React.useMemo(() => {
    if (!newStats || !dogeStats) return false;

    return (
      newStats.endurance > dogeStats.endurance ||
      newStats.agility > dogeStats.agility ||
      newStats.speed > dogeStats.speed
    );
  }, [newStats]);

  const isAffordable = React.useMemo(() => {
    if (!dogeStats || dtrkFees === 0) return true;
    return userDoge.tokenBalance >= dtrkFees;
  }, [userDoge, dtrkFees]);

  function handleChange(e, type) {
    const stat = e.target.id;
    if (
      (newStats[stat] === 100 && type === 'inc') ||
      (newStats[stat] === 0 && type === 'dec')
    )
      return;

    setNewStats((newStats) => ({
      ...newStats,
      [stat]: type === 'inc' ? newStats[stat] + 1 : newStats[stat] - 1,
    }));
  }

  let loadingTO;

  async function trainDoge() {
    try {
      if (!userDoge) return;

      loadingTO = setTimeout(() => setLoading('updateLoading', true), 100);

      const { mint, metadata, tokenAddress, dogeAddress } = userDoge;

      await upgradeDoge(connection, wallet, newStats, {
        dogeMint: mint,
        dogeMetadata: metadata,
        dtrkToken: tokenAddress,
        dogeToken: dogeAddress,
      });

      setLoading('updateLoading', true);

      setUserDoge((userDoge) => ({
        ...userDoge,
        ...newStats,
        tokenBalance: userDoge.tokenBalance - dtrkFees,
      }));

      const cachedDoges = JSON.parse(
        localStorage.getItem('userDoges') || "{'userDoges': '[]'}"
      );

      localStorage.setItem(
        'userDoges',
        JSON.stringify({
          userDoges: cachedDoges.userDoges.map((doge) => {
            return doge.name === `Genesis Doge #${dogeId}`
              ? {
                  ...userDoge,
                  ...newStats,
                  tokenBalance: userDoge.tokenBalance - dtrkFees,
                }
              : doge;
          }),
          cacheUpdatedAt: Math.floor(Date.now() / 1000),
        })
      );

      setDtrkFees(0);
    } catch (err) {
      /// TODO: Craft a nice error message UI
      setShowError('error');
      console.error(err.message);
    } finally {
      clearTimeout(loadingTO);
      setLoading('updateLoading', false);
    }
  }

  const cancelAll = () => setNewStats(dogeStats);
  const setLoading = (type, value) =>
    setLoadings((curLoad) => ({ ...curLoad, [type]: value }));

  useEffect(() => setTimeout(setLoading, 4300, 'gifAnimationEnd', true), []);

  const firstLoading =
    !(userDoge && newStats && loadings.loadedImg && loadings.gifAnimationEnd) &&
    !loadings.updateLoading;

  useEffect(() => {
    const root = document.getElementById('root');
    root.classList.add('noMotion');
    return () => {
      root.classList.remove('noMotion');
    };
  });

  return (
    <>
      {firstLoading ? <StyledLoading src={TrainingLoad} alt='loading' /> : ''}

      {loadings.updateLoading ? (
        <StyledUpdateLoading width='320' height='320' autoPlay muted loop>
          <source src={UpdateDog} type='video/mp4' />
        </StyledUpdateLoading>
      ) : (
        ''
      )}

      <CSSTransition
        in={!firstLoading && !loadings.updateLoading}
        classNames='noTransitionEnd justFade'
        timeout={400}
      >
        <StyledTraining className='training__container'>
          <Nft
            dogeId={dogeId}
            tokenBalance={userDoge?.tokenBalance}
            userDoge={userDoge}
            setLoading={setLoading}
          />

          <StyledDAEquipStats className='training-stats'>
            <EquipStatsItem title='Stats' className='stats__container'>
              <Stats
                dogeStats={dogeStats}
                newStats={newStats}
                handleChange={handleChange}
              />
            </EquipStatsItem>

            <EquipStatsItem
              title={
                <>
                  Improvements{' '}
                  <StyledCancelAll onClick={() => cancelAll()}>
                    Cancel all
                  </StyledCancelAll>
                </>
              }
              className='content__improvements'
            >
              <Improvemets
                dogeStats={dogeStats}
                newStats={newStats}
                isAffordable={isAffordable}
                isStatChanged={isStatChanged}
                trainDoge={trainDoge}
                dtrkFees={dtrkFees}
              />
            </EquipStatsItem>
          </StyledDAEquipStats>

          <div className='dogeo-title'>
            Doge<span>-O</span>
          </div>
        </StyledTraining>
      </CSSTransition>

      <Error
        error={showError}
        setError={setShowError}
        classNames='solana-error'
        text='An error has occurred, please try again.'
      />
    </>
  );
};

export default React.memo(Training);
