import React from 'react';
import {StyledMenuItem} from "./StyledMenuItem";
import CSSTransition from "react-transition-group/CSSTransition";

const MenuItem = ({className, activeClassName, i, onClick, title, to, }) => {
    return (
        <CSSTransition appear in classNames="slideInLeft" timeout={400} key={i}>
            <StyledMenuItem className={className} activeClassName={activeClassName} i={i} to={to ? to : "#"} onClick={onClick}>
                {title}
            </StyledMenuItem>
        </CSSTransition>
    );
};

export default React.memo(MenuItem);