export const sorting = [
    'Names (From A to Z)',
    'Names (From Z to A)',
    'Highest winrate first',
    'Lowest winrate first',
    'Highest classes first',
    'Lowest classes first'
]

export const trackSorting = [
    'Names (From A to Z)',
    'Names (From Z to A)',
    'In Race first',
    'Available first',
]

export const trackTypes = [
    "Dirt", "Sand", "Space", "Pavement"
]