import styled from "styled-components";
import {baseTheme} from "../../../../styles/baseTheme";

export const StyledCreateLobbyItem = styled.div`
    margin-bottom: 16px;
    display: block;
    .create__lobby-title {
      font-family: ${baseTheme.fonts.secondary};
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #FFFFFF;
      margin-bottom: 12px;
    }
  .create__lobby-content {
      position: relative;
    &::after {
      position: absolute;
      right: 15px;
      bottom: 6px;
      content: ${(props) => props.value ? "'$DTRK'" : " none"};
      //content: "flex";}
  }
`