import styled from "styled-components";
import {baseTheme} from "../../../../../styles/baseTheme";

export const StyledImprovementsItem = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #747496;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
  height: 33%;
  text-transform: capitalize;
  &:nth-of-type(2n + 1) {
    background: rgba(255, 255, 255, 0.05);
  }
  
`


export const StyledChar = styled.div`
  font-family: ${baseTheme.fonts.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: ${({isUpgrade}) => isUpgrade ? "#2BA866" : "#9898B0"};
  svg {
    margin-right: 5px;
  }
`
