import React, {useEffect, useState} from 'react';
import {StyledReturnLobby} from "./StyledReturnLobby";
import CSSTransition from "react-transition-group/CSSTransition";
import {ReactComponent as Exit} from "../../../assets/img/icons/exit.svg";
import PrimaryButton from "../../General/Buttons/Primary/PrimaryButton";
import * as API from "../../../api/api";
import useAPI from "../../../Hooks/useAPI";

const ReturnLobby = ({chosenTrack, setChooseTrack, setSpinner}) => {

    const {lobbies, setUser} = useAPI();
    const [message, setMessage] = useState("")

    const close = () => setChooseTrack(false);

    const checkIf24HoursPassed = async () => {
        const finnedLobby = lobbies.find(lobby => lobby.track._id === chosenTrack._id);

        try {
            if (finnedLobby) {
                // if ( Date.now() - new Date(findLobby.createdAt).getTime() > 24 * 3600000 ) {
                //     if (finnedLobby.out)
                //         setMessage('In the process of getting a track from the lobby')
                //     else {
                            setSpinner(true);
                            const returnLobby = await API.returnLobby(chosenTrack._id);
                //         if (finnedLobby.players.length)
                //             setMessage("Your track will be returned to inventory after the race");
                            if (returnLobby) {
                                setMessage("Success");
                                setUser(user => ({
                                    ...user, tracks: user.tracks.map((track) => {
                                        return track._id === chosenTrack._id ? {...track, inRace: false} : track
                                    })
                                }))
                            }
                // }

                // }
                // else setMessage("Within 24 hours after adding a track to the lobby, a refund is not possible")
            } else setMessage("The track is not used anywhere")
        } catch (e) {
            setMessage(e);
        }
        finally {
            setSpinner(false);
        }
    }

    useEffect(()=>{
        if(chosenTrack && chosenTrack.inRace)
            checkIf24HoursPassed()
    }, [chosenTrack])

    return (
        <CSSTransition in={!!chosenTrack && chosenTrack.inRace && !!message} classNames="fade" timeout={400} unmountOnExit >
            <StyledReturnLobby>
                <div className="overlay" onClick={()=>close()}/>
                <div className="return-lobby">
                    <Exit onClick={()=>close()} className="return-lobby-exit"/>
                    <h2 className="return-lobby-title">
                        {message}
                    </h2>
                    <PrimaryButton content="Okay" onClick={()=>close()}/>
                </div>
            </StyledReturnLobby>
        </CSSTransition>
    );
};

export default React.memo(ReturnLobby);
