import React from 'react';
import {StyledImprovementsItem, StyledChar} from "./StyledImprovementsItem";

import {ReactComponent as ArrUp} from "../../../../../assets/img/icons/arrUp.svg";

const ImprovementsItem = ({feature, newFeature, name}) => {

    const isUpgrade = newFeature > feature;

    return (
        <StyledImprovementsItem>
            {name}
            <StyledChar isUpgrade={isUpgrade}>
                {isUpgrade ?
                    <>
                        <ArrUp/>
                        {newFeature}
                        (+{newFeature - feature})
                    </>
                    : feature
                }
            </StyledChar>
        </StyledImprovementsItem>
    );
};

export default React.memo(ImprovementsItem);