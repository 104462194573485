import React from 'react';
import {StyledMenu} from "./StyledMenu";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const Menu = ({children, className, onClickAway}) => {
    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <StyledMenu className={"menu__list " + className}>
                {children}
            </StyledMenu>
        </ClickAwayListener>
    );
};

export default React.memo(Menu);
