import styled from "styled-components";
import {baseTheme} from "../../styles/baseTheme";

export const StyledSpecialSelector = styled.div`
  display: inline-flex;
  border-bottom: 1px solid #494960;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 26px;
  position: relative;
  
  .tab__select {
    font-family: ${baseTheme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #9898B0;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: .3s;
    padding: 0;
    &:nth-child(1) {
      margin-right: 22px;
    }
    &.active {
      color: ${baseTheme.colors.secondary};
    }
    &:hover {
      color: ${baseTheme.colors.hoverSecondary};
    }
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    width: 33px;
    height: 3px;
    border-radius: 12px 12px 0 0;
    background-color: ${baseTheme.colors.secondary};
    transition: .3s;
    left: 0;
    margin-left: ${props => props.showFirst ? "12%" : "66%"};
  }
  
  &.dog-analyzer-tabs:after {
    width: ${props => props.showFirst ? "49px" : "45px"};
    margin-left: ${props => props.showFirst ? "2%" : "59%"};
  }
`
