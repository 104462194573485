import React, {useEffect, useState} from "react";
import {Route, useLocation} from "react-router-dom";
import Wallet from "../Tabs/Wallet";
import DogeTrain from "../Tabs/DogeTrain";
import useAPI from "../Hooks/useAPI";
import Spinner from "../uiComponents/Helpers/Spinner/spinner";
import CSSTransition from "react-transition-group/CSSTransition";
import TrainingLoad from "../assets/loaders/trainingLoad.gif";

const Routes = () => {
    const location = useLocation();

    const {user, setUser} = useAPI();

    const [shouldShowPopUp, setShouldShowPopUp] = useState(true);
    const [isOpenPopUp, setIsOpenPopUp] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const closePopUp = () => {
        document.getElementById("popUp136")?.classList.add("fadeOut");
        const closingHandler = () => {
            setIsOpenPopUp(false);
            setShouldShowPopUp(false);
        };
        setTimeout(closingHandler, 501);
    };

    const popUp = shouldShowPopUp && isOpenPopUp;

    useEffect(() => {
        !user?.length && !location.pathname.includes("wallet") ? setIsOpenPopUp(true) : null;
    }, [user?.length, location.pathname]);

    return (
        <>
            <CSSTransition in={spinner} classNames="fade" timeout={400} unmountOnExit>
                <Spinner/>
            </CSSTransition>
            <Route exact path="/">
                <Wallet setShowPopUp={closePopUp} setSpinner={setSpinner}/>
            </Route>
            <Route exact path="/train/:dogeId">
                <DogeTrain setSpinner={setSpinner}/>
            </Route>
            <img src={TrainingLoad} alt="" style={{display: "none"}}/>
        </>
    );
};

export default React.memo(Routes);
