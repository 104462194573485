import styled from "styled-components";
import {baseTheme} from "../../../styles/baseTheme";

export const StyledFooter = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 66px;
  background-color: #090922;
  color: #fff;
  font-family: ${baseTheme.fonts.secondary};
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  text-transform: capitalize;
  box-sizing: border-box;
  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer-links {
    a {
      margin-right: 30px;
    }
  }
  @media ${baseTheme.media.mobile} {
    height: 110px;
    line-height: 28px;

    .footer-links {
      display: flex;
      flex-direction: column;
    }
  }
`
