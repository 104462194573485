import React from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {ReactComponent as Exit} from "../../assets/img/icons/exit.svg";

import {StyledFilterSelect} from "./StyledFilterSelect";
import WinRateSlider from "./WinRateSlider/WinRateSlider";
import PrimaryButton from "../General/Buttons/Primary/PrimaryButton";

const FilterSelect = ({filterSelectData}) => {

    const {filters, setFilter, filterOptions, activeFilterItems, activeColumnFilter, resetFilter, setShowFilters, checkActiveVariantFilter, handleFilterVariants}
        = filterSelectData;

    return (
        <StyledFilterSelect className="filters-container" winratefilter={filters?.winRate}>
            <TableRow className="filter">
                {filters?.winRate ?
                    <TableCell colSpan="4" className="winRateFilter">
                        <WinRateSlider filters={filters} setFilter={setFilter}/>
                    </TableCell>
                    : null
                }
                <TableCell colSpan="8">
                    <h3>{filterOptions?.name} Filter</h3>
                    <PrimaryButton className={"filter-item " + (!filterOptions?.index ? "active " : "") + "all"} onClick={() => resetFilter()} content="ALL" />
                    {activeFilterItems.map((item, filterColumn) => (
                        <PrimaryButton className={"filter-item " + checkActiveVariantFilter(item, activeColumnFilter)} key={filterColumn} onClick={() => handleFilterVariants(item, activeColumnFilter)} content={item?.name} />
                    ))}
                    <button className="close" onClick={() => setShowFilters(false)}>Close <Exit/></button>
                </TableCell>
            </TableRow>
            <TableRow className="space"/>
        </StyledFilterSelect>
    );
};

export default React.memo(FilterSelect);