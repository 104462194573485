import React, {useEffect, useState} from 'react';
import CreateLobbyItem from "./CreateLobbyItem/CreateLobbyItem";
import Input from "../../General/Input/Input";
import {filtersLIB} from "../../../InfoData/tableFilters";
import PrimaryButton from "../../General/Buttons/Primary/PrimaryButton";
import CSSTransition from "react-transition-group/CSSTransition";
import DropDown from "../../General/DropDown/DropDown";
import {trackTypes} from "../../../InfoData/walletFilters";
import Counter from "../../General/Counter/Counter";

import Success from "../Success/Success";
import * as API from "../../../api/api";
import Error from "../Error/Error";

import useAPI from "../../../Hooks/useAPI";
import {StyledCreateLobby} from "./StyledCreateLobby";
import {ReactComponent as Exit} from "../../../assets/img/icons/exit.svg";

const CreateLobby = ({chosenTrack, setChooseTrack, setSpinner}) => {

    const {setUser} = useAPI();

    const [formData, setFormData] = useState({
        name: "",
        event_type: null,//filtersLIB[3][lobbyClassIndex]?.filterKey
        amount_of_coin: "",
        laps: 1,
        track_type: null //trackTypes[trackTypeIndex],
    })

    const setField = (field, value) => setFormData(curFormData => ({...curFormData, [field]: value}))

    const setLaps = (value) => setField("laps", value);
    const setEventName = (value) => setField("name", value);
    const setTrackType = (value) => setField("track_type", value);
    const setAmountOfCoin = (value) => /^\d+$/.test(value) || value === ""  ? setField("amount_of_coin", value) : null;

    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);

    const submit = () => {
        if(validateForm())
            onSuccessCreatingLobby();
        else showErrors();
    }

    const onSuccessCreatingLobby = async () => {
        setSpinner(true);
        const newLobby = createNewLobby();
        try {
            const {data} = await API.createLobby(newLobby);
            setSuccess(data);
            setUser(user => ({...user, tracks: user.tracks.map((track)=>
                track._id === chosenTrack._id ? {...track, inRace: true} : track
            )}))
        } catch (e) {
            console.log(e);
            setSuccess("error");
        } finally {
            closeCreating();
            setSpinner(false);
        }
    }

    const createNewLobby = () => ({
        track: chosenTrack._id,
        location: chosenTrack.name,
        stat: 1,
        players: [],
        ...formData,
        track_type: trackTypes[formData.track_type],
        amount_of_coin: Number(formData.amount_of_coin)
    })

    const validateForm = () => Object.values(formData).length === Object.values(formData).filter(item => {
        if(!item)
            if (typeof item === 'number') {
                return item >= 0;
            }
            else return false
        else return true
    }).length


    const showErrors = () => {
        const setBooleanErrors = (key, value) => setErrors(curErrors => ({...curErrors, [key]: value}));
        // alert(JSON.stringify(Object.entries(formData)))
        for (const [key, value] of Object.entries(formData)) {
            if(!value)
                if (typeof value === 'number') {
                    if(value < 0) setBooleanErrors(key, true);
                    else setBooleanErrors(key, false)
                }
                else setBooleanErrors(key, true)
            else setBooleanErrors(key, false)
        }

    }

    const closeCreating = () => setChooseTrack(false);

    const resetFormData = () => {
        setFormData({
            name: "",
            event_type: "",//filtersLIB[3][lobbyClassIndex]?.filterKey
            amount_of_coin: "",
            laps: 1,
            track_type: null //trackTypes[trackTypeIndex],
        });
        setErrors({});
    }

    useEffect(()=> {
       Object.entries(errors).length ? showErrors() : null;
    }, [formData])

    useEffect(()=> {
        if(chosenTrack && !chosenTrack.inRace)
            document.querySelector('.focusMe')?.focus();
    }, [chosenTrack])

    return (
        <>
        <CSSTransition in={!!chosenTrack && !chosenTrack.inRace} classNames="fade" timeout={400} onExited={resetFormData} unmountOnExit >
            <StyledCreateLobby>
                <div className="overlay" onClick={()=>closeCreating()}/>
                <div className="create__lobby-container">
                    <Exit className="create__lobby-exit" onClick={()=>closeCreating()}/>

                    <h2>Create New Lobby</h2>

                    <CreateLobbyItem title="Name of Event" error={errors.name}>
                        <Input placeholder="Event name" value={formData.name} onChange={setEventName} type="number" className="focusMe"/>
                    </CreateLobbyItem>

                    <CreateLobbyItem title="Doge class" error={errors.event_type}>
                        {filtersLIB[3].map((clas, i) => (
                            <PrimaryButton content={clas.name} className="form__button" active={clas.filterKey === formData.event_type} key={clas.name} onClick={()=>setField("event_type", filtersLIB[3][i].filterKey)} />
                        ))}
                    </CreateLobbyItem>

                    <CreateLobbyItem title="Entry fee" error={errors.amount_of_coin} value={formData.amount_of_coin}>
                        <Input placeholder="10$DTRK" value={formData.amount_of_coin} onChange={setAmountOfCoin} maxLength={4} className="entry-fee__input" />
                    </CreateLobbyItem>

                    <CreateLobbyItem title="Laps" error={errors.laps}>
                        <Counter counter={formData.laps} setCounter={setLaps} maxCounter={4}/>
                    </CreateLobbyItem>

                    <CreateLobbyItem title="Track type" error={errors.track_type}>
                        <DropDown title="Select track type" variants={trackTypes} activeVariant={formData.track_type} setActiveVariant={setTrackType}/>
                    </CreateLobbyItem>

                    <PrimaryButton content="Create Lobby" className="create__lobby" onClick={()=>submit()}/>
                </div>
            </StyledCreateLobby>
        </CSSTransition>
        <Success success={success} setSuccess={setSuccess} />
        <Error error={success} setError={setSuccess} />
        </>
    );
};

export default React.memo(CreateLobby);
