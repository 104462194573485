import {
  CreateMetadataV2,
  Creator,
  DataV2,
  Metadata,
  UpdateMetadataV2,
} from "@metaplex-foundation/mpl-token-metadata";

const DEMO_URI =
  "https://bafybeihka5b3upxmgbpcjikb6rqffmebabupyqwhtvbhpzbctekabkiznq.ipfs.nftstorage.link/1015.json";

const DEMO_CREATORS = [
  {
    address: "7peSDz2zzkJaBPNCCYZCsANchqxJfdnC1d6DR2664WTK",
    verified: 1,
    share: 0,
  },
  {
    address: "RRUMF9KYPcvNSmnicNMAFKx5wDYix3wjNa6bA7R6xqA",
    verified: 0,
    share: 100,
  },
];

const metadataData = new DataV2({
  collection: null,
  uses: null,
  creators: DEMO_CREATORS.map(
    (c) =>
      new Creator({
        share: c.share,
        verified: c.verified === 1,
        address: c.address,
      })
  ),
  name: "Genesis Doge #1234",
  symbol: "GD",
  sellerFeeBasisPoints: 600,
  uri: DEMO_URI,
});

export async function createMetadataInstruction(mintAuthority, mint) {
  const metadata = await Metadata.getPDA(mint);

  return {
    ix: new CreateMetadataV2(
      { feePayer: mintAuthority.publicKey },
      {
        metadata,
        metadataData,
        mint,
        mintAuthority: mintAuthority.publicKey,
        updateAuthority: mintAuthority.publicKey,
      }
    ).instructions,
    metadata,
  };
}

export async function changeUpdateAuthorityInstruction(
  mint,
  updateAuthority,
  newUpdateAuthority
) {
  const metadata = await Metadata.getPDA(mint);
  return new UpdateMetadataV2(
    {
      feePayer: updateAuthority.publicKey,
    },
    {
      metadata,
      metadataData,
      updateAuthority: updateAuthority.publicKey,
      newUpdateAuthority,
    }
  ).instructions;
}
