import styled from "styled-components";
import Button from "@mui/material/Button";
import {baseTheme} from "../../../../styles/baseTheme";

export const StyledBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .stat-buttons {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
  }

  .bars__container {
    font-size: 0;
    width: ${({noTrain}) => noTrain ? "100%" : "75%"};

    .stats {
      display: flex;
      justify-content: space-between;
      margin: 0 0 9px;
      position: relative;
      text-transform: capitalize;
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;

      .stat-value {
        text-align: center;
        pointer-events: none;
        left: 0;
        right: 0;
      }
    }
  }

  .gray {
    color: #747496;
  }

  //@media (max-width: 380px) {
  //  position: relative;
  //  .bars__container {
  //    width: 100%;
  //
  //    .stats {
  //      padding-right: 60px;
  //      margin-top: 6px;
  //    }
  //  }
  //  .stat-buttons {
  //    position: absolute;
  //    right: 0;
  //    bottom: 9px;
  //  }
  //}
`


export const StyledBarItem = styled.div`
  display: inline-block;
  width: 17%;
  height: 4px;
  left: 0px;
  top: 0px;
  background: #44445F;
  position: relative;
  border-radius: 24px;

  &::after, &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
  }

  &::before {
    background-color: #1CA97F;
    z-index: 2;
    ${({featureW}) => handleBar(featureW)};
  }

  &::after {
    background-color: rgba(44, 143, 114, 0.5);
    z-index: 1;
    ${({newFeatureW}) => handleBar(newFeatureW)};
  }

  &:not(:last-child) {
    margin-right: 3.75%;
  }
`

export const handleBar = (width) => {
    const radius = width !== `100%` && width !== `0%` ?
        `border-top-right-radius: 0;
         border-bottom-right-radius: 0;`
        : ``
    return `width: ${width};` + radius;
}


export const StyledStatButton = styled(Button)`
  && {
    min-width: 0;
    width: 26px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #494960;
    border: none;
    border-radius: 12px 0;
    transition: .3s;
    padding: 0;
    margin-left: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 2px;
    font-family: ${baseTheme.fonts.primary};

    &:disabled {
      background: rgba(73, 73, 96, 0.25);
      color: #fff;
    }
    
    @media(hover: none) {
      &:hover {
        background: #494960;
      }
      &:disabled {
        background: rgba(73, 73, 96, 0.25);
        color: #fff;
      }
    }
    @media (max-width: 380px) {
      //width: 23px;
      //height: 21px;
    }
  }
`
