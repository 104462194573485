import styled from "styled-components";
import {baseTheme} from "../../../../../styles/baseTheme";

export const StyledWalletList = styled.div`
  font-family: ${baseTheme.fonts.secondary};
  position: absolute;
  border-bottom-right-radius: 12px;
  background-color: ${baseTheme.colors.secondary};
  left: 0;
  right: 0;
  color: #fff;
  transition: .4s;
  top: 0;
  z-index: 1;
  transform: translateY(${({open}) => open ? "55px" : "-55px"});
  clip-path: inset(${({open}) => open ? "0 0 0 0" : "100% 0 0 0"});
  opacity: ${({open}) => open ? 1 : 0};
  pointer-events: ${({open}) => open ? "initial" : "none"};
  padding: 15px 0;
  p, a {
    display: block;
    cursor: pointer;
    text-transform: capitalize;
    text-align: center;
    font-size: 14px;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  @media (max-width: 900px) {
    transform: translateY(${({open}) => open ? "38px" : "-75px"});
  }


  && + .connect {
    z-index: 2;
    ${({open}) => open ? "border-bottom-right-radius: 0;" : ""};
    width: 161px;
    &::after {
      position: relative;
      top: ${({open}) => open ? "-3px" : "3px"};
      transform: rotateX(${({open}) => open ? "180deg" : "0deg"});
    }

    @media (max-width: 900px) {
      width: 120px;
      padding: 10px 0;
      font-size: 13px;
    }

    @media ${baseTheme.media.mobile} {
      width: 130px;
    }
  }

  @media ${baseTheme.media.mobile} {
    transform: translateY(${({open}) => open ? "38px" : "-68px"});
    padding: 13px 0;

    p, a {
      font-size: 13px;
    }
  }
`
